import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtTokenRef, serverURL } from '../../httpContext';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import ProfileDropdown from '../../components/ProfileDropdown';
import { RenameFilePopup } from '../../components/RenameFilePopup';
import { extractMeetingId } from '../../utils';

const RecentFiles = ({ recentFiles, loadMoreFiles, navigate }) => {
  const [currentFiles, setCurrentFiles] = useState(recentFiles);
  const currentFilesRef = useRef(currentFiles);
  const scrollingContainerRef = useRef(null);
  const scrollEndRef = useRef(null);
  const observer = useRef(null);
  const [openRenameFilePopup, setOpenRenameFilePopup] = useState(false);
  const [fileName, setFileName] = useState('');
  const [fileId, setFileId] = useState('');

  const lastScrollTopRef = useRef(0);

  const handleFileRemove = (fileToRemove) => {
    console.log('calling delete transcript ' + JSON.stringify(fileToRemove));
    fetch(serverURL + '/delete_transcript?transcript_id=' + fileToRemove.id, {
      method: 'POST',
      body: JSON.stringify({ fileName: fileToRemove.name }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtTokenRef.current}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          console.log('File deleted successfully');
          const newFiles = currentFiles.filter(
            (file) => file.id !== fileToRemove.id,
          );
          setCurrentFiles(newFiles);
        } else {
          console.error('Failed to delete file');
        }
      })
      .catch((error) => {
        console.error('Error deleting file:', error);
      });
  };

  const handleFileRenamePopup = () => {
    setOpenRenameFilePopup(true);
  };

  const handleFileRename = async (name) => {
    console.log('rename file ' + fileId + ' to ' + name);
    setOpenRenameFilePopup(false);
    const response = await fetch(
      serverURL +
        '/rename_transcript?transcript_id=' +
        fileId +
        '&new_name=' +
        name,
      {
        method: 'POST',
        body: JSON.stringify({ fileName: name }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtTokenRef.current}`,
        },
      },
    );

    if (response.ok) {
      console.log('File renamed successfully');
      const newFiles = currentFiles.map((file) => {
        if (file.id === fileId) {
          file.name = name;
        }
        return file;
      });
      setCurrentFiles(newFiles);
    } else {
      console.error('Failed to rename file');
    }
  };

  useEffect(() => {
    currentFilesRef.current = currentFiles;
  }, [currentFiles]);

  useEffect(() => {
    setCurrentFiles(recentFiles);
  }, [recentFiles]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        console.log('intersecting', currentFilesRef.current.length);
      }
      if (entries[0].isIntersecting && currentFilesRef.current.length > 0) {
        lastScrollTopRef.current = scrollingContainerRef.current.scrollTop;
        console.log('lastScrollTopRef.current', lastScrollTopRef.current);
        loadMoreFiles();
      }
    }, options);

    const target = scrollEndRef.current;
    if (target) {
      observer.current.observe(target);
    }

    return () => {
      if (observer.current && target) {
        observer.current.unobserve(target);
      }
    };
  }, []);

  useEffect(() => {
    setCurrentFiles(recentFiles);
  }, [recentFiles]);

  return (
    <div
      ref={scrollingContainerRef}
      className='h-[400px] overflow-y-auto flex flex-col items-start justify-start text-sm text-updated-color-grey3'
    >
      {openRenameFilePopup && (
        <RenameFilePopup
          onClose={() => setOpenRenameFilePopup(false)}
          onSubmit={handleFileRename}
          name={fileName}
        />
      )}
      <div className='flex flex-col items-start w-full justify-start py-[5px] px-0 relative gap-[10px] cursor-pointer'>
        {currentFiles.map((file) => (
          <div
            key={file.id}
            className='relative w-full'
            onClick={() => {
              navigate(`/file_transcript?id=${file.id}`, {
                state: {
                  file: file,
                },
              });
            }}
          >
            <div className='rounded-3xs bg-app-close-to-white-grey-bkg grid grid-cols-4 py-2.5 pl-2.5 pr-2 box-border z-[0] text-base text-updated-color-new-black'>
              <div className='relative leading-[22px] font-medium'>
                {file.name}
              </div>
              <div className='leading-[22px] font-medium z-[1]'>
                {file.transcript_type === 'gpu'
                  ? 'Audio/Video Transcript'
                  : file.transcript_type === 'image'
                    ? 'Image Transcription'
                    : ''}
              </div>
              <div className=' leading-[22px] font-medium z-[2] whitespace-nowrap'>
                {format(parseISO(file.created_at), 'MMM dd, yyyy')}
              </div>
              <div className='flex flex-row items-start justify-end gap-[5px] z-[3] pr-10'>
                <div
                  className='rounded-4xl overflow-hidden flex flex-row items-start justify-start p-[5px]'
                  onClick={(e) => {
                    e.stopPropagation();
                    setFileName(file.name);
                    setFileId(file.id);
                    handleFileRenamePopup();
                  }}
                >
                  <img className='w-4 h-4' alt='' src='/icon-frame1.svg' />
                </div>
                {/* <div className="rounded-4xl overflow-hidden flex flex-row items-start justify-start p-[5px]">
									<img className="w-4 h-4" alt="" src="/icon-frame2.svg" />
								</div>
								<div className="rounded-4xl overflow-hidden flex flex-row items-start justify-start p-[5px]">
									<img className="w-4 h-4" alt="" src="/icon-frame3.svg" />
								</div> */}
                <div
                  className='rounded-4xl overflow-hidden flex flex-row items-start justify-start p-[5px]'
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFileRemove(file);
                  }}
                >
                  <img className='w-4 h-4' alt='' src='/icon-frame4.svg' />
                </div>
                {/* <div className="rounded-4xl overflow-hidden flex flex-row items-start justify-start p-[5px]">
									<img className="w-4 h-4" alt="" src="/icon-frame5.svg" />
								</div> */}
              </div>
            </div>
          </div>
        ))}
        <div ref={scrollEndRef}></div>
      </div>
    </div>
  );
};

const SearchBar = ({
  fetchFiles,
  setRecentFiles,
  currentPageRef,
  transcriptTypeRef,
  queryRef,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(null);

  const handleSearchChange = (event) => {
    const newQuery = event.target.value;
    setSearchQuery(newQuery);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(() => {
        handleSearch(newQuery);
      }, 2000),
    );
  };

  const handleSearch = async (query) => {
    currentPageRef.current = 1;
    queryRef.current = query;
    const result = await fetchFiles(
      1,
      transcriptTypeRef.current,
      false,
      queryRef.current,
    );
    setRecentFiles(result.files);

    console.log('searching for', queryRef.current);
  };

  return (
    <div className='w-96 rounded-full [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.1),_rgba(121,_119,_209,_0.1)),_#fff] flex flex-row items-center justify-start py-2.5 pl-5 text-lg text-updated-color-grey2'>
      <div className='flex flex-row items-center justify-start gap-[15px] w-full'>
        <img
          className='w-[22.9px] relative h-[22.9px] overflow-hidden shrink-0'
          alt=''
          src='/general--search1.svg'
        />
        <input
          className='w-full relative font-medium bg-transparent border-none outline-none font-montserrat text-lg'
          placeholder='Search in My Documents'
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>
    </div>
  );
};

const Docs = () => {
  const navigate = useNavigate();
  const [recentFiles, setRecentFiles] = useState([]);
  const recentFilesRef = useRef(recentFiles);
  const transcriptTypeRef = useRef(undefined);
  const [imageTranslateFilesTotal, setImageTranslateFilesTotal] = useState(0);
  const [gpuTranslateFilesTotal, setGpuTranslateFilesTotal] = useState(0);
  const currentPageRef = useRef(1);
  const queryRef = useRef('');
  const ascRef = useRef(false);

  const addBotToMeeting = async (meetingUrl) => {
    if (jwtTokenRef.current == undefined) {
      return;
    }

    const response = await axios.post(
      `${serverURL}/add_bot_to_zoom`,
      { meeting_url: meetingUrl },
      {
        headers: {
          Authorization: `Bearer ${jwtTokenRef.current}`,
        },
      },
    );
    if (response.status === 200) {
      console.log('Bot added to meeting');
    } else {
      console.error('Failed to add bot to meeting');
    }
    meetingUrl = extractMeetingId(meetingUrl);
    if (meetingUrl) {
      navigate(`/broadcast/${meetingUrl}`);
    }
  };

  // useEffect(() => {
  // 	recentFilesRef.current = recentFiles;
  // }, [recentFiles]);

  const fetchFiles = async (page, transcript_type, asc, query) => {
    try {
      console.log('fetching files');
      await jwtTokenRef.current;
      let response;
      try {
        response = await axios.get(`${serverURL}/list_transcripts`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
          params: {
            folder_id: null, // Update as needed
            page_size: 10, // Update as needed
            page_number: page, // Use the page parameter
            get_raw_transcript: false, // Update as needed,
            transcript_type: transcript_type,
            asc: asc,
            text_search: query,
          },
        });
      } catch (error) {
        console.error('Error fetching transcripts:', error);
        return;
      }
      let data = response.data;
      const total = data.total;
      data = data.transcripts;
      console.log(data);
      const retrievedFiles = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].transcript === null && data[i].transcript_type !== 'file') {
          continue;
        }
        retrievedFiles.push(data[i]);
      }
      return {
        files: retrievedFiles,
        total: total,
      };
    } catch (error) {
      console.error('Error fetching files:', error);
      return [];
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      var result = fetchFiles(1); // Load the first page on component mount
      var fetchedImageTranslateTotal = fetchFiles(1, 'image');
      const fetchedGpuTranslateTotal = await fetchFiles(1, 'gpu');
      fetchedImageTranslateTotal = await fetchedImageTranslateTotal;
      result = await result;
      setRecentFiles(result.files);
      setImageTranslateFilesTotal(fetchedImageTranslateTotal.total);
      setGpuTranslateFilesTotal(fetchedGpuTranslateTotal.total);
    };
    fetchData();
  }, []);

  const loadMoreFiles = () => {
    const fetchData = async () => {
      const nextPage = currentPageRef.current + 1;
      currentPageRef.current = nextPage;
      const result = await fetchFiles(
        nextPage,
        transcriptTypeRef.current,
        ascRef.current,
        queryRef.current,
      );
      if (result.files.length === 0) {
        return;
      }
      setRecentFiles([...recentFilesRef.current, ...result.files]);
    };

    fetchData();
  };

  return (
    <div className='w-full relative bg-white text-left text-xs text-updated-color-new-black font-montserrat'>
      <ProfileDropdown />
      <div className='flex flex-col p-10 justify-center gap-20 min-h-screen'>
        <div className='flex items-center justify-between w-full'>
          <SearchBar
            fetchFiles={fetchFiles}
            setRecentFiles={setRecentFiles}
            currentPageRef={currentPageRef}
            transcriptTypeRef={transcriptTypeRef}
            queryRef={queryRef}
          />
          <div className='flex flex-col gap-4'>
            <div className='flex items-center w-[300px] rounded-full'>
              <img src='/cam.svg' alt='' className='w-6 h-6 -mr-7' />
              <input
                placeholder='Paste meeting URL to get caption'
                className='text-base px-4 pl-8 py-2 w-full border-solid rounded-full border-[.5px] focus:outline-none bg-transparent'
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    addBotToMeeting(e.target.value);
                  }
                }}
              />
            </div>
            <button
              className='w-[250px] bg-blue-600 hover:bg-blue-700 text-white rounded-full py-2 px-6 flex items-center gap-2 shadow-lg transition duration-300 ease-in-out transform hover:scale-105'
              onClick={() => {
                alert(
                  'Coming Soon! Contact support@interpretapp.ai for early access.',
                );
              }}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20' // Reduced width for better alignment
                height='20' // Reduced height
                viewBox='0 0 50 50'
                fill='white'
                stroke='white'
                strokeWidth='2'
              >
                <path d='M 16.380859 4 C 9.5545055 4 4 9.5545055 4 16.380859 L 4 33.619141 C 4 40.445495 9.5545055 46 16.380859 46 L 33.619141 46 C 40.445495 46 46 40.445495 46 33.619141 L 46 16.380859 C 46 9.5545055 40.445495 4 33.619141 4 L 16.380859 4 z M 16.380859 6 L 33.619141 6 C 39.364786 6 44 10.635214 44 16.380859 L 44 33.619141 C 44 39.364786 39.364786 44 33.619141 44 L 16.380859 44 C 10.635214 44 6 39.364786 6 33.619141 L 6 16.380859 C 6 10.635214 10.635214 6 16.380859 6 z M 37.203125 16.628906 C 36.897671 16.625906 36.588652 16.704536 36.306641 16.880859 L 30.673828 20.400391 C 29.994839 18.439614 28.178052 17 25.994141 17 L 13 17 A 1.0001 1.0001 0 0 0 12 18 L 12 27.994141 C 12 30.746526 14.253474 33 17.005859 33 L 30 33 A 1.0001 1.0001 0 0 0 31 32 L 31 28.802734 L 36.306641 32.119141 C 37.434686 32.824447 39 31.957395 39 30.626953 L 39 18.373047 C 39 17.375216 38.119486 16.63778 37.203125 16.628906 z M 37 18.804688 L 37 30.195312 L 31 26.445312 L 31 22.554688 L 37 18.804688 z M 14 19 L 25.994141 19 C 27.665755 19 29 20.334245 29 22.005859 L 29 27 L 29 31 L 17.005859 31 C 15.334245 31 14 29.665755 14 27.994141 L 14 19 z'></path>
              </svg>
              <span className='text-base'>Integrate with Zoom</span>
            </button>
          </div>
        </div>
        <div className='flex flex-col gap-4'>
          <div className='flex flex-row items-center justify-start text-xl'>
            <div className='relative font-semibold'>File Types</div>
          </div>
          <div className='flex gap-4'>
            <div className='w-fit text-base'>
              <div
                className='cursor-pointer'
                onClick={() => {
                  const fetchData = async () => {
                    currentPageRef.current = 1;
                    transcriptTypeRef.current = 'gpu';
                    const result = await fetchFiles(
                      currentPageRef.current,
                      'gpu',
                      ascRef.current,
                      queryRef.current,
                    ); // Load the first page on component mount
                    setRecentFiles(result.files);
                  };
                  fetchData();
                }}
              >
                <div className='px-6 shadow-[0px_1px_3.3px_rgba(140,_140,_140,_0.09)] rounded-3xs bg-app-close-to-white-grey-bkg box-border flex py-5 relative gap-4 z-[0] border-[0.5px] border-solid border-updated-color-grey1'>
                  <div className='rounded-3xs bg-updated-color-purple flex flex-col items-start justify-start py-[13px] px-[11px] gap-[10px] z-[1]'>
                    <img
                      className='w-[25px] relative h-[19.4px] z-[0]'
                      alt=''
                      src='/vector-70.svg'
                    />
                    {/* <img
										className="w-[5.6px] h-[5.6px] object-contain z-[1]"
										alt=""
										src="/polygon-6.svg"
									/> */}
                  </div>
                  <div className='flex flex-col gap-2'>
                    <div className='relative font-medium z-[0]'>
                      Audio/Video Transcripts
                    </div>
                    <div className='relative text-sm text-updated-color-grey2 z-[1]'>
                      {gpuTranslateFilesTotal} files
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex text-base'>
              <div
                className='cursor-pointer'
                onClick={() => {
                  const fetchData = async () => {
                    currentPageRef.current = 1;
                    transcriptTypeRef.current = 'image';
                    const result = await fetchFiles(
                      currentPageRef.current,
                      'image',
                      ascRef.current,
                      queryRef.current,
                    ); // Load the first page on component mount
                    setRecentFiles(result.files);
                  };
                  fetchData();
                }}
              >
                <div className='px-6 shadow-[0px_1px_3.3px_rgba(140,_140,_140,_0.09)] rounded-3xs bg-app-close-to-white-grey-bkg box-border flex py-5 relative gap-4 z-[0] border-[0.5px] border-solid border-updated-color-grey1'>
                  <div className='rounded-3xs bg-updated-color-blue flex flex-col items-start justify-start p-2.5 z-[1]'>
                    <img
                      className='w-[27.5px] relative h-[25px]'
                      alt=''
                      src='/vector.svg'
                    />
                  </div>
                  <div className='flex flex-col gap-2'>
                    <div className='relative font-medium z-[0]'>
                      Image Translate
                    </div>
                    <div className='relative text-sm text-updated-color-grey2 z-[1]'>
                      {imageTranslateFilesTotal} files
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col gap-4'>
          <div className='flex items-center justify-start text-xl'>
            <div className='relative font-semibold'>Recent Files</div>
          </div>
          <div className='flex flex-row items-start justify-start gap-[20px] text-sm'>
            <div
              className='rounded-33xl [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.1),_rgba(121,_119,_209,_0.1)),_#fff] flex flex-row items-center justify-center py-[5px] px-3 cursor-pointer'
              onClick={() => {
                const fetchData = async () => {
                  currentPageRef.current = 1;
                  ascRef.current = false;
                  const result = await fetchFiles(
                    currentPageRef.current,
                    transcriptTypeRef.current,
                    false,
                    queryRef.current,
                  ); // Load the first page on component mount
                  setRecentFiles(result.files);
                };
                fetchData();
              }}
            >
              <div className='relative leading-[22px] font-medium'>Latest</div>
            </div>
            <div
              className='rounded-33xl [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.1),_rgba(121,_119,_209,_0.1)),_#fff] flex flex-row items-center justify-center py-[5px] px-3 cursor-pointer'
              onClick={() => {
                const fetchData = async () => {
                  currentPageRef.current = 1;
                  ascRef.current = true;
                  const result = await fetchFiles(
                    currentPageRef.current,
                    transcriptTypeRef.current,
                    true,
                    queryRef.current,
                  ); // Load the first page on component mount
                  setRecentFiles(result.files);
                };
                fetchData();
              }}
            >
              <div className='relative leading-[22px] font-medium'>
                Earliest
              </div>
            </div>
          </div>
          <div className='grid grid-cols-4 w-full h-[22px] text-sm text-updated-color-grey3'>
            <div className=' leading-[22px] font-medium'>Name</div>
            <div className=' leading-[22px] font-medium'>Type</div>
            <div className=' leading-[22px] font-medium'>Created</div>
          </div>
          <RecentFiles
            recentFiles={recentFiles}
            loadMoreFiles={loadMoreFiles}
            navigate={navigate}
          />
        </div>
      </div>
    </div>
  );
};

export default Docs;
