import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { serverURL } from '../../httpContext';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const navigate = useNavigate();

  const onGroupIconClick = useCallback(() => {
    navigate('/login');
  }, [navigate]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSendLink = async () => {
    try {
      await axios.post(`${serverURL}/forgot-password`, { email: email });
      setEmailSent(true);
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendLink();
    }
  };

  return (
    <div className='w-full relative bg-white h-[982px] overflow-hidden text-left text-21xl text-not-black-black font-montserrat'>
      <div className='absolute top-[207px] left-[216px] flex flex-col items-start justify-start'>
        <div className='relative leading-[123%] font-semibold'>
          Forgot Password
        </div>
      </div>
      <div className='absolute top-[0px] left-[50px] w-[1362px] h-24 flex flex-row items-center justify-start pt-[55px] px-0 pb-[30px] box-border gap-[796px] text-5xl text-updated-color-blue'>
        <div className='w-[183px] h-[41px] flex flex-row items-center justify-start'>
          <div
            className='w-44 relative h-[41px] cursor-pointer'
            onClick={() => {
              navigate('/');
            }}
          >
            <div className='absolute w-[73.86%] top-[14.63%] left-[26.14%] tracking-[-0.02em] font-medium inline-block'>
              interpret
            </div>
            <img
              className='absolute h-full w-[23.3%] top-[0%] right-[76.7%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full'
              alt=''
              src='/1024px-x-1024px-round-w-text1.svg'
            />
          </div>
        </div>
        <div className='flex flex-row items-start justify-start gap-[50px] text-center text-xl text-not-black-black'>
          <div className='flex flex-row items-center justify-center py-[5px] px-[15px]'>
            <div className='relative font-medium opacity-[0]'>About</div>
          </div>
          <div className='flex flex-row items-center justify-center py-[5px] px-[15px]'>
            <div className='relative font-medium opacity-[0]'>Pricing</div>
          </div>
          <div className='flex flex-row items-center justify-center py-[5px] px-[15px]'>
            <div className='relative font-medium opacity-[0]'>Login</div>
          </div>
        </div>
      </div>
      {!emailSent ? (
        <>
          <div className='absolute top-[calc(50%_-_40px)] left-[216px] flex flex-col items-start justify-start text-center text-lg text-updated-color-grey2'>
            <div className='flex flex-col items-start justify-start gap-[60px]'>
              <div className='flex flex-row items-center justify-start gap-[10px]'>
                <img
                  className='w-7 relative h-7 overflow-hidden shrink-0'
                  alt=''
                  src='/communication--email1.svg'
                />
                <input
                  type='email'
                  className='w-[423px] py-2.5 px-2 border-x-0 border-t-0 border-b-1 border-updated-color-grey1 font-montserrat focus:outline-none'
                  placeholder='Enter your email'
                  value={email}
                  onChange={handleEmailChange}
                  onKeyDown={handleKeyPress}
                  autoFocus
                />
              </div>
              <button
                onClick={handleSendLink}
                className='w-[460px] rounded-3xs bg-updated-color-blue flex flex-row items-center justify-center p-2.5 box-border text-left text-9xl text-white'
              >
                <div className='relative leading-[123%] font-semibold'>
                  Send Link
                </div>
              </button>
            </div>
          </div>
          <div className='absolute top-[276px] left-[217px] text-xl leading-[123%] text-unselected-text inline-block w-[417px]'>
            Enter your email address, and we’ll send you a link to reset your
            password.
          </div>
          <div className='absolute top-[0px] left-[756px] bg-updated-color-grey1 w-[756px] h-[982px]' />
          <div className='absolute top-[443px] left-[977px] text-13xl leading-[123%] font-semibold text-black text-center'>
            {/* <p className="m-0">placeholder</p>
            <p className="m-0">use scenario photo</p> */}
          </div>
          <img
            className='absolute h-[2.75%] w-[1.79%] top-[22.2%] right-[92.59%] bottom-[75.05%] left-[5.62%] max-w-full overflow-hidden max-h-full cursor-pointer'
            alt=''
            src='/group-1707478204.svg'
            onClick={onGroupIconClick}
          />
        </>
      ) : (
        <div className='absolute top-[calc(50%_-_44px)] left-[calc(50%_-_540px)] flex flex-col items-start justify-start gap-[20px] text-center text-xl text-updated-color-new-black'>
          <div className='relative font-medium'>
            We just sent an email to your inbox with a link.
          </div>
          <div className='relative text-lg text-unselected-text'>
            <span>{`Didn’t receive email! `}</span>
            <span
              className='[text-decoration:underline] text-dodgerblue-200 cursor-pointer'
              onClick={handleSendLink}
            >
              Resend
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
