import { useState, useEffect, useRef } from 'react';
import { FoldersContainer } from '../../components/mobile/FoldersContainer';
import { SearchOutlined } from '@ant-design/icons';
import { jwtTokenRef, serverURL } from '../../httpContext';
import axios from 'axios';
import { Files } from '../../components/mobile/Files';

export const Docs = () => {
  const [searching, setSearching] = useState(false);
  const [recentFiles, setRecentFiles] = useState([]);
  const [query, setQuery] = useState('');
  const queryRef = useRef(query);
  const typingTimeoutRef = useRef(null);

  const processFile = (file) => {
    return {
      name: file.name,
      content: file.transcript,
      created_at: file.created_at,
      path: file.path,
      id: parseInt(file.id),
      raw_transcript: file.raw_transcript,
      transcript_type: file.transcript_type,
    };
  };

  useEffect(() => {
    queryRef.current = query;

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      refreshFiles();
    }, 1000);

    return () => {
      clearTimeout(typingTimeoutRef.current);
    };
  }, [query]);

  const onSearchBarContainerClick = () => {
    setSearching(true);
  };

  const refreshFiles = async () => {
    try {
      const response = await axios.get(
        `${serverURL}/list_transcripts?text_search=${queryRef.current}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        },
      );
      const data = response.data.transcripts;
      const retrievedFiles = data.map(processFile);
      setRecentFiles(retrievedFiles);
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };

  useEffect(() => {
    refreshFiles();
  }, []);

  return (
    <div className='h-full flex flex-col pt-16 px-[1.25rem] gap-5 leading-[normal] tracking-[normal] relative overflow-y-scroll'>
      <div className='flex items-center justify-between py-[0.25rem] gap-[0.625rem] w-full'>
        <SearchOutlined className='text-2xl text-updated-color-grey2 -mr-10 pt-2 relative z-10' />
        <input
          className={`${
            searching ? 'w-4/5' : 'w-full'
          } transition-all duration-300 ease-in-out pl-10 [border:none] [outline:none] rounded-xl bg-updated-color-grey1 font-montserrat text-[1rem] h-8 leading-[1.375rem] text-updated-color-grey2 text-left`}
          placeholder='search'
          type='text'
          onClick={onSearchBarContainerClick}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        {searching && (
          <span
            className={`text-base text-updated-color-blue overflow-hidden transition-all duration-300 ease-in-out ${
              searching ? 'w-1/5 opacity-100' : 'w-0 opacity-0'
            }`}
            onClick={() => {
              setSearching(false);
              setQuery('');
            }}
          >
            Cancel
          </span>
        )}
      </div>
      {query && <div className='text-lg font-light'>Search results</div>}
      {!query && <FoldersContainer numFolders={3} />}
      <Files
        title={'Recently Saved'}
        recentFiles={recentFiles}
        setRecentFiles={setRecentFiles}
        refreshFiles={refreshFiles}
        queryRef={queryRef}
      />
    </div>
  );
};
