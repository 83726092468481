import React from 'react';

export const ZoomInstructions = () => {
  return (
    <div className='h-full font-montserrat flex flex-col gap-14 px-10 pb-20 sm:px-48'>
      <div className='text-[40px] font-semibold text-not-black'>
        How to Use Interpret AI with Zoom
      </div>
      <div className='text-updated-color-grey2 font-medium text-sm leading-6'>
        Follow these steps to integrate Interpret AI with your Zoom meetings:
      </div>
      <ol className='list-decimal list-inside text-updated-color-grey2 font-medium text-sm leading-6 space-y-4'>
        <li>
          <strong>Click on the "Integrate with Zoom" button:</strong> Navigate
          to the Interpret AI dashboard and locate the "Integrate with Zoom"
          button to begin the integration process.
        </li>
        <li>
          <strong>Complete the integration:</strong> Follow the on-screen
          prompts to connect your Zoom account with Interpret AI. Once the
          integration is complete, you can start or join a Zoom meeting as
          usual.
        </li>
        <li>
          <strong>Copy the Zoom meeting invite link:</strong> During your Zoom
          meeting, click on the top toolbar under the <em>Meeting</em> tab and
          select <strong>Copy Invite Link</strong> to copy the meeting link to
          your clipboard.
        </li>
        <li>
          <strong>Paste the invite link into Interpret AI:</strong> In the
          Interpret AI app, locate the input field designated for the Zoom link.
          Paste the copied invite link into this field and press{' '}
          <strong>Enter</strong> to submit.
        </li>
        <li>
          <strong>Wait for the Interpret AI bot to join the call:</strong> After
          submitting the link, you will be redirected to the broadcast page.
          Wait a moment for the Interpret AI bot to automatically join your Zoom
          call and start providing transcription and translation services.
        </li>
      </ol>
      <div className='text-updated-color-grey2 font-medium text-sm leading-6'>
        If you encounter any issues during the integration process, please refer
        to our{' '}
        <a href='/support' className='text-blue-500 underline'>
          Support Page
        </a>{' '}
        or contact our customer support team at{' '}
        <a
          href='mailto:support@bluejayai.net'
          className='text-blue-500 underline'
        >
          support@bluejayai.net
        </a>
        .
      </div>
    </div>
  );
};
