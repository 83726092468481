import { useState, useCallback, useRef, useEffect } from 'react';
import SelectTypesOfRoomToJoin from './SelectTypesOfRoomToJoin';
import SelectTypesOfRoomToStart from './SelectTypesOfRoomToStart';

const StartjoinARoomOverlay = ({ onOutsideClickFunc }) => {
  const [isSelectTypesOfRoomToJoinOpen, setSelectTypesOfRoomToJoinOpen] =
    useState(false);
  const [isSelectTypesOfRoomToStartOpen, setSelectTypesOfRoomToStartOpen] =
    useState(false);

  const isSelectTypesOfRoomToJoinOpenRef = useRef(
    isSelectTypesOfRoomToJoinOpen,
  );
  const isSelectTypesOfRoomToStartOpenRef = useRef(
    isSelectTypesOfRoomToStartOpen,
  );

  const startJoinRef = useRef(null); // Create a ref for the div

  const openSelectTypesOfRoomToStart = useCallback(() => {
    isSelectTypesOfRoomToStartOpenRef.current = true;
    setSelectTypesOfRoomToStartOpen(true);
  }, []);

  const closeSelectTypesOfRoomToStart = useCallback(() => {
    isSelectTypesOfRoomToStartOpenRef.current = false;
    setSelectTypesOfRoomToStartOpen(false);
  }, []);

  const openSelectTypesOfRoomToJoin = useCallback(() => {
    isSelectTypesOfRoomToJoinOpenRef.current = true;
    setSelectTypesOfRoomToJoinOpen(true);
  }, []);

  const closeSelectTypesOfRoomToJoin = useCallback(() => {
    isSelectTypesOfRoomToJoinOpenRef.current = false;
    setSelectTypesOfRoomToJoinOpen(false);
  }, []);

  // Detect clicks outside the ref'ed div
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        startJoinRef.current &&
        !startJoinRef.current.contains(event.target) &&
        !isSelectTypesOfRoomToJoinOpenRef.current &&
        !isSelectTypesOfRoomToStartOpenRef.current
      ) {
        onOutsideClickFunc();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onOutsideClickFunc]);

  return (
    <div className='fixed inset-0 z-50 bg-overlay-new-background flex items-center justify-center text-left text-base text-updated-color-new-black font-montserrat'>
      {!isSelectTypesOfRoomToJoinOpen && !isSelectTypesOfRoomToStartOpen && (
        <div className={`flex justify-center h-fit p-10 w-1/3`}>
          <div
            ref={startJoinRef}
            className='rounded-lg cursor-pointer bg-gray-200 shadow-[0_3px_5.5px_rgba(140,_140,_140,_0.25)] w-full overflow-hidden flex flex-col items-center justify-center py-[15px] box-border'
          >
            <div
              className='flex items-center justify-center py-5 px-10 w-fit'
              onClick={openSelectTypesOfRoomToStart}
            >
              <div className='font-medium w-full text-center'>Start a Room</div>
            </div>
            {/* Separator Line with increased visibility */}
            <div className='w-11/12 h-[.5px] bg-black my-2'></div>
            <div
              className='flex items-center justify-center py-5 px-10 cursor-pointer w-full'
              onClick={openSelectTypesOfRoomToJoin}
            >
              <div className='font-medium w-full text-center'>Join a Room</div>
            </div>
          </div>
        </div>
      )}
      {isSelectTypesOfRoomToJoinOpen && (
        <SelectTypesOfRoomToJoin
          onClose={closeSelectTypesOfRoomToJoin}
          onOutsideClickFunc={closeSelectTypesOfRoomToJoin}
        />
      )}
      {isSelectTypesOfRoomToStartOpen && (
        <SelectTypesOfRoomToStart
          onClose={closeSelectTypesOfRoomToStart}
          onOutsideClickFunc={closeSelectTypesOfRoomToStart}
        />
      )}
    </div>
  );
};

export default StartjoinARoomOverlay;
