import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import {
  jwtTokenRef,
  serverURL,
  updateAccountInfo,
  userInfoRef,
} from '../../httpContext';
import {
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
  getAuth,
} from 'firebase/auth';

const Signup = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const emailField = useRef(null);
  const passwordField = useRef(null);

  const onAlreadyHaveAnClick = useCallback(() => {
    navigate('/login');
  }, [navigate]);

  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({ prompt: 'select_account' });

  const auth = getAuth();

  const handleSignup = async () => {
    try {
      const response = await axios.post(`${serverURL}/signup`, {
        email: email || emailField.current.value,
        password: password || passwordField.current.value,
      });

      const response_json = response.data;

      if (response.status === 201) {
        console.log('Signup successful', response_json);
        jwtTokenRef.current = response_json.access_token;
        await updateAccountInfo(jwtTokenRef, userInfoRef);
        localStorage.setItem('jwtToken', jwtTokenRef.current);
        setError('');
        navigate('/email-verification');
      } else if (response.status < 500) {
        setError(response_json.detail || 'Invalid email or password');
      } else {
        setError(response_json.detail);
      }
    } catch (error) {
      setError(error.response?.data?.detail || 'Internal server error');
    }
  };

  const handleGoogleSignup = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const token = await user.getIdToken();

      const response = await axios.post(`${serverURL}/login-with-google`, {
        token,
      });

      jwtTokenRef.current = response.data.access_token;
      localStorage.setItem('jwtToken', jwtTokenRef.current);
      await updateAccountInfo(jwtTokenRef, userInfoRef);

      navigate('/transcribe');
    } catch (error) {
      setError('Authentication failed. Please try again.');
    }
  };

  // useEffect(() => {
  //   onAuthStateChanged(auth, (user) => {
  //     if (user) {
  //       handleGoogleSignup();
  //     }
  //   });
  // }, []);

  return (
    <div className='w-full relative bg-white h-[982px] overflow-hidden text-left text-21xl text-not-black-black font-montserrat'>
      <div className='absolute top-[207px] left-[calc(50%_-_301px)] flex flex-col items-center justify-start gap-[65px]'>
        <div className='relative leading-[123%] font-semibold'>
          Create an Account
        </div>
        <div className='flex flex-col items-center justify-start gap-[40px] text-xl text-updated-color-new-black'>
          <div className='flex flex-col items-start justify-start gap-[10px]'>
            <div className='relative leading-[123%] font-medium'>Email</div>
            <input
              type='email'
              className='w-[602px] relative rounded-3xs bg-white box-border h-[53px] border-[1px] border-solid border-updated-color-grey2'
              ref={emailField}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSignup()}
            />
          </div>
          <div className='flex flex-col items-start justify-start relative gap-[10px]'>
            <div className='relative leading-[123%] font-medium z-[0]'>
              Password
            </div>
            <input
              type={showPassword ? 'text' : 'password'}
              className='w-[602px] relative rounded-3xs bg-white box-border h-[53px] z-[1] border-[1px] border-solid border-updated-color-grey2'
              ref={passwordField}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSignup()}
            />
            {showPassword ? (
              <EyeInvisibleOutlined
                className='w-6 absolute !m-[0] top-[50px] left-[566px] h-6 overflow-hidden shrink-0 z-[2]'
                onClick={() => setShowPassword(false)}
              />
            ) : (
              <EyeOutlined
                className='w-6 absolute !m-[0] top-[50px] left-[566px] h-6 overflow-hidden shrink-0 z-[2]'
                onClick={() => setShowPassword(true)}
              />
            )}
          </div>
          <button
            onClick={handleSignup}
            className='w-[602px] rounded-3xs bg-updated-color-blue flex flex-row items-center justify-center p-2.5 box-border text-9xl text-white'
          >
            <div className='relative leading-[123%] font-semibold'>Sign Up</div>
          </button>
          {error && <div className='text-red-500 mt-2'>{error}</div>}
          <div
            className='relative font-medium text-center cursor-pointer text-updated-color-blue'
            onClick={onAlreadyHaveAnClick}
          >
            <span className='text-updated-color-grey2'>
              Already have an account?
            </span>
            <span className='text-updated-color-new-black'>{` `}</span>
            <span className='[text-decoration:underline]'>Log in here</span>
          </div>
          <div className='flex flex-col items-center justify-start gap-[20px]'>
            <button
              onClick={handleGoogleSignup}
              className='w-[602px] rounded-3xs bg-white border-[1px] border-solid border-updated-color-grey2 flex flex-row items-center justify-center p-2.5 box-border text-9xl text-black'
            >
              <img
                className='h-8 w-8 mr-3'
                alt='Sign up with Google'
                src='/google@2x.png'
              />
              <div className='relative leading-[123%] font-semibold'>
                Sign Up with Google
              </div>
            </button>
          </div>
        </div>
      </div>
      <img
        className='absolute top-[499px] left-[992px] w-6 h-6 overflow-hidden'
        alt=''
        src='/general.svg'
      />
      <div className='absolute top-[0px] left-[50px] w-[1362px] h-24 flex flex-row items-center justify-start pt-[55px] px-0 pb-[30px] box-border gap-[796px] text-5xl text-updated-color-blue'>
        <div className='w-[183px] h-[41px] flex flex-row items-center justify-start'>
          <div
            className='w-44 relative h-[41px] cursor-pointer'
            onClick={() => navigate('/')}
          >
            <div className='absolute w-[73.86%] top-[14.63%] left-[26.14%] tracking-[-0.02em] font-medium inline-block'>
              interpret
            </div>
            <img
              className='absolute h-full w-[23.3%] top-[0%] right-[76.7%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full'
              alt=''
              src='/1024px-x-1024px-round-w-text1.svg'
            />
          </div>
        </div>
        <div className='flex flex-row items-start justify-start gap-[50px] text-center text-xl text-not-black-black'>
          <div className='flex flex-row items-center justify-center py-[5px] px-[15px]'>
            <div className='relative font-medium opacity-[0]'>About</div>
          </div>
          <div className='flex flex-row items-center justify-center py-[5px] px-[15px]'>
            <div className='relative font-medium opacity-[0]'>Pricing</div>
          </div>
          <div className='flex flex-row items-center justify-center py-[5px] px-[15px]'>
            <div className='relative font-medium opacity-[0]'>Login</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
