// import { ArrowRightOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom';

const LearnMoreContainer = () => {
  return (
    <div className='bg-gradient-to-br from-[#007EF3] to-[#7977D1] flex justify-center items-center md:justify-start px-10 sm:p-16 py-9 text-white font-montserrat'>
      <div className='w-full flex flex-col gap-6 sm:gap-x-36 sm:gap-10 flex-wrap sm:flex-row'>
        <div className='text-sm'>
          <p className='text-xl font-semibold'>About</p>
          <p className='opacity-60'>BlueJay AI Technologies, Inc</p>
          {/* <p className='opacity-60'>interpret App</p> */}
          <p className='opacity-60'>support@interpretapp.ai</p>
        </div>
        <div className='text-sm'>
          <p className='text-xl font-semibold'>Privacy and Terms</p>
          <div className='flex flex-col gap-4'>
            <Link className='opacity-60 text-white' to='/tos'>
              Terms of Service
            </Link>
            <Link className='opacity-60 text-white' to='/privacy'>
              Privacy Policy
            </Link>
          </div>
        </div>
        <div className='text-sm'>
          <p className='text-xl font-semibold'>Social</p>
          {/* <p>Subscribe to our newsletter</p>
				<div className='flex'>
					<input
						style={{
							borderRadius: 20,
							fontSize: 16,
						}}
						className='placeholder:text-not-black/60 bg-white/10 h-11 pl-2'
						type='email'
						placeholder='Email'
						onChange={(e) => {}}
						onKeyDown={(e) => {}}
					/>
					<button className='-ml-6'>
						<ArrowRightOutlined />
					</button>
				</div> */}
          <a
            href='https://www.linkedin.com/company/interpretapp/'
            className='text-white opacity-60'
          >
            LinkedIn
          </a>
        </div>
        <p className='text-xs basis-full'>
          © Copyright {new Date().getFullYear()} Bluejay Ai Technologies Inc.
        </p>
      </div>
    </div>
  );
};

export default LearnMoreContainer;
