const plans = [
  {
    name: 'Free',
    price: '100 Credits',
    description: [
      'As a new user, enjoy 100 free credits for your first month. Unlock full access to all features and content-completely free.',
    ],
    buttonText: 'Try for Free',
    buttonOpacity: 0.5,
  },
  {
    name: 'Lite',
    price: '$8.99/mo',
    description: [
      '1000 credits monthly',
      'Real time translation & transcription: 100 credits/hour.',
      'Document translation: 40 credits/page',
      'Zoom transcription & translation: 100 credits/hour',
    ],
    buttonText: 'Start now',
    buttonOpacity: 0.5,
  },
  {
    name: 'Pro',
    price: '$24.99/mo',
    description: [
      '3000 credits monthly',
      'Real time translation & transcription: 100 credits/hour.',
      'Document translation: 40 credits/page',
      'Zoom transcription & translation: 100 credits/hour',
    ],
    buttonText: 'Start now',
    buttonOpacity: 0.5,
  },
  {
    name: 'Enterprise',
    price: 'Let’s Talk!',
    description: ['Please contact us to start the service.'],
    buttonText: 'Contact us',
    buttonOpacity: 0.5,
  },
];

const Plan = () => {
  return (
    <div className='h-full px-10 sm:px-28 pb-44 pt-12 bg-white flex flex-col gap-32 justify-center items-center text-sm text-not-black-black font-montserrat'>
      <PlanHeader />
      <div className='flex md:flex-row h-full flex-col gap-[30px] text-xl text-white'>
        {plans.map((plan, index) => {
          if (index === 2) {
            return (
              <div
                key={index}
                className='w-full relative mt-10 md:mt-0 flex flex-col items-center justify-center gap-10'
              >
                <div className='absolute w-full -top-12 rounded-t-xl h-fit pt-5 pb-10 text-sm z-0 flex flex-col items-center justify-center bg-updated-color-blue font-semibold'>
                  MOST POPULAR
                </div>
                <PlanCard key={index} plan={plan} isPopular={index === 2} />
              </div>
            );
          } else {
            return <PlanCard key={index} plan={plan} />;
          }
        })}
      </div>
    </div>
  );
};

const PlanHeader = () => (
  <div className='flex flex-col items-center justify-center gap-[20px] text-21xl w-full text-center'>
    <div className='font-semibold'>Plans & Pricing</div>
    <div className='max-w-[652px] relative text-sm font-medium'>
      Everything's within reach, no matter your plan. Enjoy full access to all
      features and content—the only difference is how long you can keep the fun
      going 🥳
    </div>
  </div>
);

const PlanCard = ({ plan, isPopular }) => (
  <div
    className={`rounded-xl [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.1),_rgba(121,_119,_209,_0.1)),_#fff] flex flex-col items-center py-10 px-[30px] gap-[20px] ${isPopular && 'relative z-10'}`}
    style={{ height: '345.5px' }}
  >
    <div className={`flex flex-col gap-[20px]`}>
      <div className='rounded-mini bg-updated-color-blue flex flex-row items-center justify-center py-[5px] px-[30px]'>
        <div className='relative font-medium'>{plan.name}</div>
      </div>
      <div className='flex flex-col items-start justify-start gap-[40px] text-13xl text-not-black-black'>
        <b className='relative'>{plan.price}</b>
        <div className='flex flex-col gap-2 text-xs'>
          {plan.description.map((desc, index) => {
            if (
              (plan.name !== 'Free' || plan.name !== 'Enterprise') &&
              index === 1
            ) {
              return (
                <div
                  key={index}
                  className='flex flex-row items-start justify-start gap-[10px]'
                >
                  <div className='flex flex-row items-start justify-start py-[3px] px-0'>
                    <div className='w-2 relative rounded-[50%] bg-updated-color-blue h-2' />
                  </div>
                  <div
                    key={index}
                    className='w-[218px] relative inline-block shrink-0'
                  >
                    Text translation: <b>free</b>
                  </div>
                </div>
              );
            }
            if (
              (plan.name !== 'Free' || plan.name !== 'Enterprise') &&
              index === 2
            ) {
              return (
                <div
                  key={index}
                  className='flex flex-row items-start justify-start gap-[10px]'
                >
                  <div className='flex flex-row items-start justify-start py-[3px] px-0'>
                    <div className='w-2 relative rounded-[50%] bg-updated-color-blue h-2' />
                  </div>
                  <div className='w-[218px] relative inline-block shrink-0'>
                    Image translation: <b>free</b>
                  </div>
                </div>
              );
            }
            return (
              <div
                key={index}
                className='flex flex-row items-start justify-start gap-[10px]'
              >
                <div className='flex flex-row items-start justify-start py-[3px] px-0'>
                  <div className='w-2 relative rounded-[50%] bg-updated-color-blue h-2' />
                </div>
                <div className='w-[218px] relative inline-block shrink-0'>
                  {desc}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
    {/* <div className="w-[200.1px] relative h-[43.5px] overflow-hidden shrink-0 text-center text-mini-5 text-not-black-black">
      <div
        className={`absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-4xl-2 bg-white opacity-[${plan.buttonOpacity}] mix-blend-normal`}
      />
      <div className="absolute top-[calc(50%_-_9.15px)] left-[32.03%] font-medium">
        {plan.buttonText}
      </div>
    </div> */}
  </div>
);

export default Plan;
