export function extractMeetingId(url) {
  let regex, match;

  if (url.includes('zoom.us')) {
    // Regular expression to match Zoom meeting ID
    regex = /\/j\/(\d+)/;
    match = url.match(regex);
  } else if (url.includes('teams.live.com')) {
    // Regular expression to match Microsoft Teams meeting ID
    regex = /\/meet\/(\d+)/;
    match = url.match(regex);
  }

  return match ? match[1] : null;
}
