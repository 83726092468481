import { useState, useCallback, useRef, useEffect } from 'react';
import SelectTypesOfRoomToJoin from './SelectTypesOfRoomToJoin';
import SelectTypesOfRoomToStart from './SelectTypesOfRoomToStart';
import PortalPopup from './PortalPopup';

const StartjoinARoomOverlay = ({ onOutsideClickFunc }) => {
  const [isSelectTypesOfRoomToJoinOpen, setSelectTypesOfRoomToJoinOpen] =
    useState(false);
  const [isSelectTypesOfRoomToStartOpen, setSelectTypesOfRoomToStartOpen] =
    useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const isSelectTypesOfRoomToJoinOpenRef = useRef(
    isSelectTypesOfRoomToJoinOpen,
  );
  const isSelectTypesOfRoomToStartOpenRef = useRef(
    isSelectTypesOfRoomToStartOpen,
  );

  const startJoinRef = useRef(null); // Create a ref for the div

  const openSelectTypesOfRoomToStart = useCallback(() => {
    isSelectTypesOfRoomToStartOpenRef.current = true;
    setSelectTypesOfRoomToStartOpen(true);
  }, []);

  const closeSelectTypesOfRoomToStart = useCallback(() => {
    isSelectTypesOfRoomToStartOpenRef.current = false;
    setSelectTypesOfRoomToStartOpen(false);
  }, []);

  const openSelectTypesOfRoomToJoin = useCallback(() => {
    isSelectTypesOfRoomToJoinOpenRef.current = true;
    setSelectTypesOfRoomToJoinOpen(true);
  }, []);

  const closeSelectTypesOfRoomToJoin = useCallback(() => {
    isSelectTypesOfRoomToJoinOpenRef.current = false;
    setSelectTypesOfRoomToJoinOpen(false);
  }, []);

  // Detect clicks outside the ref'ed div
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        startJoinRef.current &&
        !startJoinRef.current.contains(event.target) &&
        !isSelectTypesOfRoomToJoinOpenRef.current &&
        !isSelectTypesOfRoomToStartOpenRef.current
      ) {
        setIsVisible(false);
        setTimeout(() => {
          onOutsideClickFunc();
        }, 250);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onOutsideClickFunc]);

  return (
    <>
      <div
        className='w-full relative bg-overlay-new-background h-full overflow-hidden cursor-pointer max-w-full max-h-full text-left text-base text-updated-color-new-black font-montserrat'
        // onClick={onStartjoinARoomOverlayClick}
      >
        <div
          className={`flex justify-center items-end h-full transition-transform ${
            isVisible ? 'animate-slideUp' : 'animate-slideDown'
          }`}
        >
          <div
            ref={startJoinRef}
            className='rounded-t-xl rounded-b-none bg-gray-200 shadow-[0_3px_5.5px_rgba(140,_140,_140,_0.25)] w-full overflow-hidden flex flex-col items-center justify-center py-[15px] box-border'
          >
            <div
              className='flex items-center justify-center py-5 px-10 w-fit'
              onClick={openSelectTypesOfRoomToStart}
            >
              <div className='font-medium w-full text-center'>Start a Room</div>
            </div>
            {/* Separator Line with increased visibility */}
            <div className='w-full h-[.5px] bg-black my-2'></div>
            <div
              className='flex items-center justify-center py-5 px-10 cursor-pointer w-full'
              onClick={openSelectTypesOfRoomToJoin}
            >
              <div className='font-medium w-full text-center'>Join a Room</div>
            </div>
          </div>
        </div>
      </div>
      {isSelectTypesOfRoomToJoinOpen && (
        <PortalPopup
          overlayColor='rgba(113, 113, 113, 0.3)'
          placement='Centered'
        >
          <SelectTypesOfRoomToJoin
            onClose={closeSelectTypesOfRoomToJoin}
            onOutsideClickFunc={closeSelectTypesOfRoomToJoin}
          />
        </PortalPopup>
      )}
      {isSelectTypesOfRoomToStartOpen && (
        <PortalPopup
          overlayColor='rgba(113, 113, 113, 0.3)'
          placement='Centered'
        >
          <SelectTypesOfRoomToStart
            onClose={closeSelectTypesOfRoomToStart}
            onOutsideClickFunc={closeSelectTypesOfRoomToStart}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default StartjoinARoomOverlay;
