import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userInfoRef } from '../../httpContext';

const Homepage = () => {
  const navigate = useNavigate();

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  const onLearnMoreAboutUsClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='enablingIndividualsFacing']",
    );
    if (anchor) {
      anchor.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    const checkEmailVerification = () => {
      if (userInfoRef.current?.verified_email) {
        setIsUserLoggedIn(true);
        clearInterval(interval);
      }
    };

    const interval = setInterval(checkEmailVerification, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='w-full relative bg-white h-[2236px] overflow-hidden text-left text-5xl text-not-black-black font-montserrat'>
      <div
        className='absolute top-[938px] left-1/2 transform -translate-x-1/2 w-full flex flex-row items-center justify-center py-2.5 px-0 box-border gap-[17px] cursor-pointer text-center text-lg'
        onClick={onLearnMoreAboutUsClick}
      >
        <div className='relative font-semibold'>learn more about us</div>
        <img
          className='w-6 relative h-6 overflow-hidden shrink-0'
          alt=''
          src='/arrow--arrow-bottom-left-1.svg'
        />
      </div>

      <div className='absolute top-[316px] left-[75px] flex flex-col items-start justify-start gap-[25px] text-[46px] text-gray-400'>
        <div className='flex flex-col items-start justify-start gap-[15px]'>
          <div className='flex flex-col items-start justify-start'>
            <div className='w-[745px] relative leading-[137%] font-semibold text-transparent !bg-clip-text [background:linear-gradient(102.81deg,_#007ef3,_#7977d1)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] inline-block'>
              Every Conversation, Perfectly Captured.
            </div>
          </div>
          <div className='flex flex-col items-start justify-start text-xl'>
            <div className='w-[691px] relative leading-[137%] font-medium inline-block'>
              Elevate your communication with real-time transcription, enhance
              your conversations with precision and clarity
            </div>
          </div>
        </div>
        <div
          className='w-[222px] rounded-3xs bg-updated-color-blue flex flex-row items-center justify-center p-5 box-border text-5xl text-white cursor-pointer'
          onClick={() => {
            if (userInfoRef.current?.verified_email) navigate('/transcribe');
            else navigate('/signup');
          }}
        >
          <div className='relative tracking-[-0.41px] font-medium'>
            {isUserLoggedIn ? 'Dashboard' : 'Start for Free'}
          </div>
        </div>
      </div>
      <div className='absolute top-[1059px] left-[calc(50%_-_271px)] flex flex-col items-center justify-start gap-[10px] text-21xl'>
        <div className='flex flex-row items-start justify-start'>
          <div className='flex flex-row items-center justify-center pt-6 px-2.5 pb-2.5'>
            <div className='relative leading-[123%] font-semibold'>
              Introducing,
            </div>
          </div>
          <div className='flex flex-row items-start justify-start ml-[-8px]'>
            <div className='flex flex-row items-center justify-center pt-6 pb-2.5 pr-2.5 pl-0'>
              <div className='relative leading-[123%]'>
                <span className='font-semibold'>{` `}</span>
                <b className='text-updated-color-blue'>interpret</b>
              </div>
            </div>
            <div className='overflow-hidden flex flex-row items-start justify-start ml-[-9px]'>
              <img
                className='w-[79px] relative h-[79px]'
                alt=''
                src='/grid.svg'
              />
            </div>
          </div>
        </div>
        <div className='relative text-9xl leading-[123%] font-semibold'>
          created by BlueJay AI
        </div>
      </div>
      <div
        className='absolute top-[1238px] left-[calc(50%_-_286px)] text-lg font-medium text-updated-color-grey2 text-center inline-block w-[573px]'
        data-scroll-to='enablingIndividualsFacing'
      >
        Enabling individuals facing language barriers to enjoy seamless
        conversations with an AI-powered transcription tool.
      </div>
      <div className='relative top-[1334px] w-full max-w-[1303px] min-w-[320px] mx-auto h-[777px] text-21xl text-updated-color-new-black overflow-hidden'>
        <div className='absolute inset-0 shadow-[0px_3px_5.5px_rgba(140,_140,_140,_0.25)] rounded-xl bg-white box-border overflow-hidden border-[1px] border-solid border-updated-color-grey1'>
          <div className='absolute inset-0 rounded-xl bg-app-close-to-white-grey-bkg' />
          <div className='absolute top-[28px] left-[5%] right-[5%] rounded-xl bg-white max-w-[387px] w-full h-[722px]'>
            <img
              className='absolute top-[37px] left-[5%] w-full max-w-[347px] h-[648px] object-cover'
              alt=''
              src='/mask-group@2x.png'
            />
          </div>
          <div className='absolute top-[65px] left-[55%] right-[5%] font-semibold inline-block w-full max-w-[503px]'>
            Simple to use mobile transcriptions.
          </div>
          <div className='absolute top-[460px] left-[55%] right-[5%] text-5xl font-medium text-updated-color-grey2 inline-block w-full max-w-[503px]'>
            Visit interpretapp.ai on your mobile device to transcribe audio,
            text, and images on the go.
          </div>
        </div>
      </div>

      <div className='absolute top-[810px] left-[calc(50%_-_563px)] flex flex-row items-center justify-start gap-[50px]'>
        <div className='relative tracking-[-0.01em] leading-[123%]'>
          Integrated with:
        </div>
        <div className='flex flex-row items-start justify-start gap-[40px]'>
          <img
            className='w-[134.9px] relative h-[50.1px] object-cover'
            alt=''
            src='/image-74@2x.png'
          />
          <div className='w-[174.7px] relative h-[50px]'>
            <img
              className='absolute top-[13px] left-[59.3px] w-[115.4px] h-[23.6px] object-cover'
              alt=''
              src='/image-76@2x.png'
            />
            <img
              className='absolute top-[0px] left-[0px] w-[53.6px] h-[50px] object-cover'
              alt=''
              src='/image-75@2x.png'
            />
          </div>
          <img
            className='w-[198px] relative h-[50px] object-cover'
            alt=''
            src='/image-77@2x.png'
          />
          <img
            className='w-[260.2px] relative h-[50.5px] object-cover'
            alt=''
            src='/image-78@2x.png'
          />
        </div>
      </div>
      <div className='absolute h-[18.34%] w-[32.94%] top-[10.24%] right-[7.41%] bottom-[71.42%] left-[59.66%]'>
        <img
          className='absolute h-[42.46%] w-[69.66%] top-[0%] right-[18.9%] bottom-[57.54%] left-[11.45%] max-w-full overflow-hidden max-h-full'
          alt=''
          src='/group-1707478120.svg'
        />
        <div className='absolute top-[79px] left-[0px] w-[498px] h-[331px]'>
          <img
            className='absolute top-[15px] left-[0px] rounded-t-none rounded-br-none rounded-bl-141xl w-[251px] h-[316px] object-cover'
            alt=''
            src='/subject-2@2x.png'
          />
          <img
            className='absolute top-[0px] left-[228px] rounded-t-none rounded-br-141xl rounded-bl-none w-[270px] h-[331px] object-cover'
            alt=''
            src='/subject@2x.png'
          />
        </div>
      </div>
    </div>
  );
};

export default Homepage;
