import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { userInfoRef } from '../httpContext';

const Nav = ({ showMobile }) => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  useEffect(() => {
    const checkEmailVerification = () => {
      if (userInfoRef.current?.verified_email) {
        setIsUserLoggedIn(true);
        clearInterval(interval);
      }
    };

    const interval = setInterval(checkEmailVerification, 1000);

    return () => clearInterval(interval);
  }, []);

  const navigate = useNavigate();

  return (
    <div className='h-full flex flex-wrap px-20 items-center sm:gap-0 gap-5 justify-center sm:justify-between pt-[55px] pb-[30px] text-updated-color-blue text-5xl font-montserrat'>
      <div
        className='w-fit h-fit cursor-pointer flex items-center gap-[5px]'
        onClick={() => {
          navigate('/');
        }}
      >
        <img
          className='h-12 w-12'
          alt=''
          src='/1024px-x-1024px-round-w-text1.svg'
        />
        <div className='tracking-[-0.02em] font-medium text-2xl'>interpret</div>
      </div>
      <div className='flex flex-col sm:flex-row gap-5 sm:gap-[50px] w-fit text-center text-xl text-not-black-black'>
        {!showMobile && (
          <div
            className='flex flex-row items-center justify-center py-[5px] px-[15px] cursor-pointer'
            onClick={() => {
              navigate('/plan');
            }}
          >
            <div className='relative font-medium'>Pricing</div>
          </div>
        )}
        <div
          className='flex items-center justify-center py-[5px] cursor-pointer'
          onClick={() => {
            if (userInfoRef.current?.verified_email) navigate('/transcribe');
            else {
              navigate('/login');
            }
          }}
        >
          <div className='relative font-medium'>
            {isUserLoggedIn ? 'Dashboard' : 'Login'}
          </div>
        </div>
        <div
          className='flex items-center justify-center py-[5px] cursor-pointer'
          onClick={() => navigate('/contact')}
        >
          <div className='relative font-medium'>Contact Us</div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
