import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useWhisper } from '@chengsokdara/use-whisper';
import { socket } from '../../socketContext';
import { languageToCode, top100WorldLanguages } from '../../languages';
import { jwtTokenRef, serverURL, translateTexts } from '../../httpContext';
import { format } from 'date-fns';
import axios from 'axios';
import ProfileDropdown from '../../components/ProfileDropdown';
import RecentsSidebar from './RecentsSidebar';
import { mdiArrowDown } from '@mdi/js';
import Icon from '@mdi/react';
import StartJoinRoom from '../../components/StartJoinRoom';

const formatTime = (milliseconds) => {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(milliseconds / (1000 * 60 * 60));
  const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
  const seconds = totalSeconds % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

const Transcribe = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const [elapsedTime, setElapsedTime] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [timerRunning, setTimerRunning] = useState(false);
  const messagesContainerRef = useRef(null);
  const correctedMessagesContainerRef = useRef(null);
  const shouldScroll = useRef(false);
  const correctedMessagesShouldScroll = useRef(false);
  const [languageDropdownVisible, setLanguageDropdownVisible] = useState(false);
  const [languageTwoDropdownVisible, setLanguageTwoDropdownVisible] =
    useState(false);
  const [shouldShowScrollButton, setShouldShowScrollButton] = useState(false);
  const [
    correctedMessagesShouldShowScrollButton,
    setCorrectedMessagesShouldShowScrollButton,
  ] = useState(false);
  const [showStartJoinARoomOverlay, setShowStartJoinARoomOverlay] =
    useState(false);

  const inputLanguage = useRef('Original');
  const [inputLanguageDisplay, setInputLanguageDisplay] = useState('Original');
  const outputLanguage = useRef('Original');
  const [outputLanguageDisplay, setOutputLanguageDisplay] =
    useState('Original');

  const [searchBarInput, setSearchBarInput] = useState(null);
  const [searchBarTwoInput, setSearchBarTwoInput] = useState(null);
  const inputLanguageRef = useRef(null);
  const outputLanguageRef = useRef(null);
  const inputLanguageDropdownRef = useRef(null);
  const outputLanguageDropdownRef = useRef(null);

  const usingInputLanguagePopupRef = useRef(false);
  const usingOutputLanguagePopupRef = useRef(false);

  const stoppedTranscribing = useRef(false);

  const isBroadcastingRef = useRef(false);
  const isInBroadcastRoomRef = useRef(false);

  const initMessages = [];
  const [messages, setMessages] = useState(initMessages);
  const [messagesMerged, setMessagesMerged] = useState(initMessages);
  const messagesRef = useRef(messages);
  const [isTranscribing, setTranscribing] = useState(false);
  const isTranscribingRef = useRef(isTranscribing);
  const playingAudioRef = useRef(false);
  const roomIdRef = useRef(null);

  // on page load, set the room id to the query parameter
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const roomId = queryParams.get('room_id');
    if (roomId) {
      roomIdRef.current = roomId;
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    console.log('loading zoom oauth callback');
    const code = queryParams.get('code');
    if (code) {
      const redirectUri = window.location.origin + window.location.pathname;
      fetch(`${serverURL}/oauth-callback/zoom`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + jwtTokenRef.current,
        },
        body: JSON.stringify({ code, redirect_uri: redirectUri }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          console.log('Success:', data);
          // Handle success (e.g., redirect or update state)
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }, []);

  useEffect(() => {
    isTranscribingRef.current = isTranscribing;
  }, [isTranscribing]);

  const startTimer = () => {
    setStartTime(new Date().getTime());
    setTimerRunning(true);
  };

  const pauseTimer = () => {
    setTimerRunning(false);
  };

  let audioRef = useRef(null);

  const updateMessagesMerged = (messagesUpdated) => {
    var messagesMergedUpdated = [];
    for (var i = 0; i < messagesUpdated.length; i++) {
      if (messagesUpdated[i]['transcript'] != '') {
        const currentTimestamp = messagesUpdated[i]['timestamp'];
        const lastMergedMessage =
          messagesMergedUpdated[messagesMergedUpdated.length - 1];

        if (
          i > 0 &&
          !messagesUpdated[i]['did_speaker_change'] &&
          !messagesUpdated[i]['is_ai_answer'] &&
          !messagesUpdated[i - 1]['is_ai_answer'] &&
          messagesMergedUpdated.length > 0 &&
          lastMergedMessage['messages_merged'] < 7 &&
          currentTimestamp - lastMergedMessage['timestamp'] <= 30
        ) {
          lastMergedMessage['transcript'] =
            lastMergedMessage['transcript'] + messagesUpdated[i]['transcript'];

          lastMergedMessage['messages_merged']++;

          for (var key in messagesUpdated[i]['corrected_transcripts']) {
            if (lastMergedMessage['corrected_transcripts'][key] == undefined) {
              lastMergedMessage['corrected_transcripts'][key] = '';
            }
            lastMergedMessage['corrected_transcripts'][key] =
              lastMergedMessage['corrected_transcripts'][key] +
              ' ' +
              messagesUpdated[i]['corrected_transcripts'][key];
          }
        } else {
          messagesUpdated[i]['messages_merged'] = 1;
          const timestamp = new Date(currentTimestamp * 1000);
          messagesUpdated[i]['timestamp_display'] =
            timestamp.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            });
          messagesMergedUpdated.push(messagesUpdated[i]);
        }
      }
    }
    setMessagesMerged(messagesMergedUpdated);
  };

  useEffect(() => {
    // click outside the language picker popup to close it
    const handleClickOutside = (event) => {
      if (usingInputLanguagePopupRef.current) {
        if (
          inputLanguageRef.current &&
          !inputLanguageRef.current.contains(event.target)
        ) {
          if (
            inputLanguageDropdownRef.current &&
            !inputLanguageDropdownRef.current.contains(event.target)
          ) {
            usingInputLanguagePopupRef.current = false;
            setLanguageDropdownVisible(false);
          }
        }
      }
      if (usingOutputLanguagePopupRef.current) {
        if (
          outputLanguageRef.current &&
          !outputLanguageRef.current.contains(event.target)
        ) {
          if (
            outputLanguageDropdownRef.current &&
            !outputLanguageDropdownRef.current.contains(event.target)
          ) {
            usingOutputLanguagePopupRef.current = false;
            setLanguageTwoDropdownVisible(false);
          }
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    socket.removeAllListeners();
    console.log('adding listener for receive_transcriptions');
    socket.on('room', (data) => {
      isBroadcastingRef.current = true;
      isInBroadcastRoomRef.current = true;
      console.log('joined room ' + data);
    });

    const urlParams = new URLSearchParams(window.location.search);
    if (roomIdRef.current) {
      console.log('joining room ' + roomIdRef.current);
    }

    socket.on('receive_transcriptions', async (data) => {
      console.log('received transcriptions ' + JSON.stringify(data));
      let messagesToAdd = [];
      let oldMessages = [...messagesRef.current];
      let message = data;

      let messageToAdd = {
        transcript_id: message['transcript_id'],
        transcript: message['transcript'],
        corrected_transcripts: message['corrected_transcripts'],
        is_final: message['is_final'],
        timestamp: message['timestamp'],
        diarization: message['diarization'],
        did_speaker_change: message['did_speaker_change'],
        is_ai_answer: message['is_ai_answer'],
      };

      let replacedMessage = false;
      for (let i = 0; i < oldMessages.length; i++) {
        if (oldMessages[i]['transcript_id'] == message['transcript_id']) {
          oldMessages[i] = messageToAdd;
          replacedMessage = true;
          messagesToAdd = [];
        }
      }

      if (!replacedMessage) {
        messagesToAdd = [message];
      }

      const messagesContainer = messagesContainerRef.current;
      if (messagesContainer) {
        const { scrollTop, clientHeight, scrollHeight } = messagesContainer;
        console.log(scrollTop, clientHeight, scrollHeight);
        console.log('dist: ', scrollHeight - clientHeight - scrollTop);
        const atBottom = Math.abs(scrollHeight - clientHeight - scrollTop) <= 1;
        if (atBottom) {
          shouldScroll.current = true;
        } else {
          setShouldShowScrollButton(true);
        }
      }
      const correctedMessagesContainer = correctedMessagesContainerRef.current;
      if (correctedMessagesContainer) {
        const { scrollTop, clientHeight, scrollHeight } =
          correctedMessagesContainer;
        console.log(scrollTop, clientHeight, scrollHeight);
        console.log('dist: ', scrollHeight - clientHeight - scrollTop);
        const atBottom = Math.abs(scrollHeight - clientHeight - scrollTop) <= 1;
        if (atBottom) {
          correctedMessagesShouldScroll.current = true;
        } else {
          setCorrectedMessagesShouldShowScrollButton(true);
        }
      }

      setMessages([...oldMessages, ...messagesToAdd]);
      let messagesUpdated = [];
      for (let i = 0; i < oldMessages.length; i++) {
        messagesUpdated = [
          ...messagesUpdated,
          JSON.parse(JSON.stringify(oldMessages[i])),
        ];
      }
      for (let i = 0; i < messagesToAdd.length; i++) {
        messagesUpdated = [
          ...messagesUpdated,
          JSON.parse(JSON.stringify(messagesToAdd[i])),
        ];
      }
      console.log('messages updated: ' + JSON.stringify(messagesUpdated));

      updateMessagesMerged(messagesUpdated);

      messagesRef.current = [...oldMessages, ...messagesToAdd];
      localStorage.setItem('messages', JSON.stringify(messagesUpdated));
    });

    return () => {
      console.log('Removing socket listeners');
      socket.removeAllListeners();
      socket.emit('stop_transcribing', { jwt_token: jwtTokenRef.current });
      const expiryDateEpochSeconds =
        Math.floor(Date.now() / 1000) + 30 * 24 * 60 * 60; // 30 days later in epoch seconds
      socket.emit('save_audio', {
        jwt_token: jwtTokenRef.current,
        clear_audio: true,
        expiry_date_epoch_seconds: expiryDateEpochSeconds,
      });
    };
  }, [pathname]);

  const onTranscribe = async (blob) => {
    if (stoppedTranscribing.current) {
      clearChunks();
      stoppedTranscribing.current = false;
      return {
        blob,
        text: '',
      };
    }

    const base64 = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });

    if (jwtTokenRef.current == undefined) {
      return;
    }

    var translateToLanguages = [];
    var possibleLanguages = [];

    if (
      inputLanguage.current != 'Detect Language' &&
      inputLanguage.current != 'Original'
    ) {
      translateToLanguages.push(inputLanguage.current);
      if (languageToCode[inputLanguage.current] != undefined) {
        possibleLanguages.push(languageToCode[inputLanguage.current]);
      }
    }

    if (
      outputLanguage.current != 'Detect Language' &&
      outputLanguage.current != 'Original'
    ) {
      translateToLanguages.push(outputLanguage.current);
      if (languageToCode[outputLanguage.current] != undefined) {
        possibleLanguages.push(languageToCode[outputLanguage.current]);
      }
    }

    if (possibleLanguages.length > 0) {
      socket.emit('possible_languages', [
        languageToCode[inputLanguage.current],
        languageToCode[outputLanguage.current],
      ]);
    }

    socket.emit('transcribe', {
      audio: base64,
      jwt_token: jwtTokenRef.current,
      translate_to_languages: translateToLanguages,
    });
    clearChunks();

    return {
      blob,
      text: '',
    };
  };

  const { transcript, recording, stopRecording, startRecording, clearChunks } =
    useWhisper({
      onDataAvailable: onTranscribe,
      onTranscribe: onTranscribe,
      streaming: true,
      timeSlice: 250,
      removeSilence: false,
      whisperConfig: {
        language: 'en',
      },
    });

  useEffect(() => {
    const messagesContainer = messagesContainerRef.current;
    if (messagesContainer) {
      let lastScrollTop = messagesContainer.scrollTop;

      const handleScroll = () => {
        const currentScrollTop = messagesContainer.scrollTop;
        if (currentScrollTop < lastScrollTop) {
          shouldScroll.current = false;
        }
        lastScrollTop = currentScrollTop;
      };

      if (messagesContainer) {
        messagesContainer.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (messagesContainer) {
          messagesContainer.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, []);

  useEffect(() => {
    const correctedMessagesContainer = correctedMessagesContainerRef.current;
    if (correctedMessagesContainer) {
      let lastScrollTop = correctedMessagesContainer.scrollTop;

      const handleScroll = () => {
        const currentScrollTop = correctedMessagesContainer.scrollTop;
        if (currentScrollTop < lastScrollTop) {
          correctedMessagesShouldScroll.current = false;
        }
        lastScrollTop = currentScrollTop;
      };

      if (correctedMessagesContainer) {
        correctedMessagesContainer.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (correctedMessagesContainer) {
          correctedMessagesContainer.removeEventListener(
            'scroll',
            handleScroll,
          );
        }
      };
    }
  }, []);

  const scrollToBottom = () => {
    setShouldShowScrollButton(false);
    messagesContainerRef.current?.scrollTo({
      top: Math.ceil(messagesContainerRef.current.scrollHeight),
      behavior: 'smooth',
    });
  };

  const correctedScrollToBottom = () => {
    setCorrectedMessagesShouldShowScrollButton(false);
    correctedMessagesContainerRef.current?.scrollTo({
      top: Math.ceil(correctedMessagesContainerRef.current.scrollHeight),
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (correctedMessagesShouldScroll.current) correctedScrollToBottom();
    if (shouldScroll.current) scrollToBottom();
  }, [messagesMerged]);

  const handleInputLanguageClick = (language) => {
    inputLanguage.current = language;
    setInputLanguageDisplay(language);
    setLanguageDropdownVisible(false);
  };

  useEffect(() => {
    let interval;
    if (timerRunning) {
      interval = setInterval(() => {
        const now = new Date().getTime();
        const elapsed = now - startTime;
        // console.log('elapsed: ' + formatTime(elapsed))
        setElapsedTime(elapsed);
        // console.log('elapsedTime: ' + elapsedTime)
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timerRunning, startTime]);

  const handleOutputLanguageClick = (language) => {
    setLanguageTwoDropdownVisible(false);
    if (language != outputLanguage.current) {
      var textsToTranslate = [];
      var messagesAlreadyTranslated = [];
      for (var i = 0; i < messages.length; i++) {
        var message = messages[i];
        if (message['corrected_transcripts'][language] != undefined) {
          messagesAlreadyTranslated.push(i);
          continue;
        }
        textsToTranslate.push(message.transcript);
      }
      console.log('texts to translate: ' + textsToTranslate);
      translateTexts(textsToTranslate, language).then((translations) => {
        console.log('translations: ' + translations);
        var updatedMessages = [];
        for (var i = 0; i < messagesRef.current.length; i++) {
          var message = messagesRef.current[i];
          if (messagesAlreadyTranslated.includes(i)) {
            updatedMessages.push(message);
            continue;
          } else {
            if (message['corrected_transcripts'][language] == undefined) {
              message['corrected_transcripts'][language] =
                translations[i] + ' ';
            }

            updatedMessages.push(message);
          }
        }
        setMessages(updatedMessages);
        updateMessagesMerged(updatedMessages);
        localStorage.setItem('messages', JSON.stringify(messagesMerged));
      });
    }
    outputLanguage.current = language;
    setOutputLanguageDisplay(language);
  };

  const handleTranscribeButton = async () => {
    console.log('isTranscribing', isTranscribingRef.current);
    if (isTranscribingRef.current) {
      stoppedTranscribing.current = true;
      isTranscribingRef.current = false;
      pauseTimer();
      await stopRecording();
      socket.emit('stop_transcribing', { jwt_token: jwtTokenRef.current });
    } else {
      stoppedTranscribing.current = false;
      isTranscribingRef.current = true;
      await startRecording();
      startTimer();
    }
    setTranscribing(isTranscribingRef.current);
  };

  const playAudio = async (text) => {
    try {
      if (playingAudioRef.current) {
        audioRef.current.pause();
        playingAudioRef.current = false;
      }
      const response = await axios.post(
        `${serverURL}/text_to_speech`,
        { text: text }, // Correctly sending the body as the second argument
        {
          headers: {
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
          responseType: 'blob', // Ensure the response is a blob
        },
      );

      if (response.status === 200) {
        playingAudioRef.current = true;
        const audioBlob = response.data;

        // Create a URL for the audio blob and handle it as needed
        const audioUrl = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioUrl);
        audio.play();
        audioRef.current = audio;
        audio.onended = () => {
          playingAudioRef.current = false;
        };
      } else {
        throw new Error(`Received status code ${response.status}`);
      }
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  const addBotToMeeting = async (meetingUrl) => {
    if (jwtTokenRef.current == undefined) {
      return;
    }

    const response = await axios.post(
      `${serverURL}/add_bot_to_zoom`,
      { meeting_url: meetingUrl },
      {
        headers: {
          Authorization: `Bearer ${jwtTokenRef.current}`,
        },
      },
    );
    if (response.status === 200) {
      console.log('Bot added to meeting');
    } else {
      console.error('Failed to add bot to meeting');
    }
    meetingUrl = extractMeetingId(meetingUrl);
    if (meetingUrl) {
      navigate(`/broadcast/${meetingUrl}`);
    }
  };

  function extractMeetingId(url) {
    let regex, match;

    if (url.includes('zoom.us')) {
      // Regular expression to match Zoom meeting ID
      regex = /\/j\/(\d+)/;
      match = url.match(regex);
    } else if (url.includes('teams.live.com')) {
      // Regular expression to match Microsoft Teams meeting ID
      regex = /\/meet\/(\d+)/;
      match = url.match(regex);
    }

    return match ? match[1] : null;
  }

  return (
    <div className='bg-white relative text-left text-base text-updated-color-new-black font-montserrat flex flex-col'>
      {showStartJoinARoomOverlay && (
        <StartJoinRoom
          onOutsideClickFunc={() => setShowStartJoinARoomOverlay(false)}
        />
      )}
      <ProfileDropdown />
      <div className='flex relative justify-center gap-2 py-14 px-24 pb-28 h-screen'>
        <div className='flex flex-col gap-8 w-full relative'>
          <div
            ref={inputLanguageRef}
            className='w-fit mx-auto rounded-34xl flex py-1.5 px-5 border-[0.5px] border-solid border-updated-color-blue cursor-pointer'
            onClick={() => {
              setLanguageDropdownVisible(!languageDropdownVisible);
              usingInputLanguagePopupRef.current =
                !usingInputLanguagePopupRef.current;
            }}
          >
            <div className='flex flex-col items-start justify-start py-1.5 px-2.5'>
              <div className='flex flex-row items-start justify-start'>
                <div className='relative font-medium'>Detect language</div>
              </div>
            </div>
            <div className='flex flex-col items-start justify-start py-1.5 px-2.5'>
              <div className='flex flex-row items-start justify-start gap-[10px] cursor-pointer'>
                <b className='relative'>{inputLanguageDisplay}</b>
                <img
                  className='w-5 relative h-5'
                  alt=''
                  src='/select-more-language.svg'
                />
              </div>
            </div>
          </div>
          {languageDropdownVisible && (
            <div
              ref={inputLanguageDropdownRef}
              className='grid grid-cols-4 gap-16 px-10 bg-white border absolute top-12 border-solid border-gray-300 rounded-md shadow-lg z-10 max-h-full overflow-y-auto w-full'
            >
              <div className='col-span-4 h-fit flex items-center p-2 border-solid border-x-0 border-t-0 border-b border-gray-300'>
                <input
                  type='text'
                  placeholder='Search...'
                  className='w-full p-1 border-none outline-none text-lg font-montserrat'
                  value={searchBarInput}
                  onChange={(e) => setSearchBarInput(e.target.value)}
                />
                <img
                  className='w-5 h-5 ml-2'
                  alt=''
                  src='/general--search.svg'
                />
              </div>
              {top100WorldLanguages
                .filter((language) =>
                  language
                    .toLowerCase()
                    .includes(searchBarInput?.toLowerCase() || ''),
                )
                .map((language) => (
                  <div
                    key={language}
                    className=' hover:bg-gray-200 cursor-pointer w-full h-fit'
                    onClick={() => handleInputLanguageClick(language)}
                  >
                    {language}
                  </div>
                ))}
            </div>
          )}
          <div className='relative shadow-[1px_1px_2.8px_rgba(140,_140,_140,_0.16)_inset] rounded-xl bg-gray box-border w-full h-[448px] border-[0.5px] border-solid border-updated-color-grey1 pb-10'>
            <div
              className='flex flex-col gap-4 overflow-y-auto max-h-[95%]'
              ref={messagesContainerRef}
            >
              {messagesMerged.map((message, index) => {
                const localTime = format(
                  new Date(message.timestamp * 1000),
                  'hh:mm:ss a',
                );
                return (
                  <div key={index} className='flex items-start m-4'>
                    <span className='mr-4 text-gray-600 w-24'>{localTime}</span>
                    <p>
                      {inputLanguage.current === 'Original' ||
                      inputLanguage.current === 'Detect Language'
                        ? message.transcript
                        : message.corrected_transcripts[inputLanguage.current]}
                    </p>
                  </div>
                );
              })}
            </div>
            {!isTranscribing ? (
              <div className='absolute bottom-2 left-0 right-0 mx-auto w-fit flex gap-[20px]'>
                <img
                  className='w-[34px] relative h-[34px] cursor-pointer'
                  alt=''
                  src='/group-1707478176.svg'
                  onClick={() => {
                    handleTranscribeButton();
                  }}
                />
                <img
                  className='w-[34px] relative h-[34px] cursor-pointer'
                  alt=''
                  src='/group-1707478175.svg'
                  onClick={() => {
                    var copiedTranscript = '';
                    for (var i = 0; i < messagesMerged.length; i++) {
                      const message = messagesMerged[i];
                      var localTime = format(
                        new Date(message.timestamp * 1000),
                        'hh:mm:ss a',
                      );
                      copiedTranscript +=
                        localTime + ': ' + message['transcript'] + '\n\n';
                    }
                    navigator.clipboard.writeText(copiedTranscript);
                  }}
                />
              </div>
            ) : (
              <div className='absolute bottom-2 left-0 right-0 mx-auto flex flex-col gap-4 items-center justify-center'>
                {shouldShowScrollButton && (
                  <div
                    className='w-fit cursor-pointer'
                    onClick={scrollToBottom}
                  >
                    <Icon path={mdiArrowDown} size={1} />
                  </div>
                )}
                <div className='flex flex-row items-center justify-center text-center text-xs'>
                  <div className='flex flex-col items-start justify-start py-2.5 px-0'>
                    <img
                      className='w-full relative max-h-full'
                      alt=''
                      src='/vector-367.svg'
                    />
                  </div>
                  <div className='w-12 relative flex items-center justify-center shrink-0'>
                    {formatTime(elapsedTime)}
                  </div>
                  <div className='flex flex-col items-start justify-start py-2.5 px-0'>
                    <img
                      className='w-full relative max-h-full'
                      alt=''
                      src='/vector-367.svg'
                    />
                  </div>
                </div>
                <div className=' w-fit flex gap-[20px]'>
                  <img
                    className='w-[34px] relative h-[34px] cursor-pointer'
                    alt=''
                    src='/group-1707478175.svg'
                    onClick={() => {
                      var copiedTranscript = '';
                      for (var i = 0; i < messagesMerged.length; i++) {
                        const message = messagesMerged[i];
                        var localTime = format(
                          new Date(message.timestamp * 1000),
                          'hh:mm:ss a',
                        );
                        copiedTranscript +=
                          localTime + ': ' + message['transcript'] + '\n\n';
                      }
                      navigator.clipboard.writeText(copiedTranscript);
                    }}
                  />
                  <img
                    className='w-[34px] relative h-[34px] cursor-pointer'
                    alt=''
                    src='/group-1707478181.svg'
                    onClick={() => {
                      handleTranscribeButton();
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <div
            className='rounded-tl-none rounded-tr-11xl rounded-br-11xl rounded-bl-none [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.1),_rgba(121,_119,_209,_0.1)),_#fff] h-11 flex flex-row items-center justify-center py-2.5 pr-5 pl-[49px] box-border -ml-24 gap-[5px] cursor-pointer w-fit'
            onClick={() => {
              setShowStartJoinARoomOverlay(true);
            }}
          >
            <div className='relative font-semibold'>Enter Broadcast</div>
            <img
              className='w-6 relative h-6 overflow-hidden shrink-0'
              alt=''
              src='/arrow--arrow-right-1.svg'
            />
          </div>
        </div>
        <div className='w-fit'>
          <img
            className='w-9 h-9 cursor-pointer'
            alt=''
            src='/switch.svg'
            onClick={() => {
              const temp = inputLanguage.current;
              inputLanguage.current = outputLanguage.current;
              outputLanguage.current = temp;
              setInputLanguageDisplay(inputLanguage.current);
              setOutputLanguageDisplay(outputLanguage.current);
            }}
          />
        </div>
        <div className='flex flex-col gap-8 w-full relative'>
          <div
            ref={outputLanguageRef}
            className='w-fit mx-auto rounded-34xl flex flex-row items-start justify-start py-1.5 px-5 border-[0.5px] border-solid border-updated-color-blue cursor-pointer'
            onClick={() => {
              usingOutputLanguagePopupRef.current =
                !usingOutputLanguagePopupRef.current;
              setLanguageTwoDropdownVisible(!languageTwoDropdownVisible);
            }}
          >
            <div className='flex flex-col items-start justify-start py-1.5 px-2.5'>
              <div className='flex flex-row items-start justify-start gap-[10px]'>
                <b className='relative'>{outputLanguageDisplay}</b>
                <img
                  className='w-5 relative h-5'
                  alt=''
                  src='/select-more-language.svg'
                />
              </div>
            </div>
          </div>
          {languageTwoDropdownVisible && (
            <div
              ref={outputLanguageDropdownRef}
              className='absolute right-0 top-12 gap-16 px-10 bg-white border-solid grid grid-cols-4 border-gray-300 rounded-md shadow-lg z-10 w-full max-h-full overflow-y-auto p-3'
            >
              <div className='flex h-fit items-center col-span-4 p-2 border-solid border-x-0 border-t-0 border-b border-gray-300'>
                <input
                  type='text'
                  placeholder='Search...'
                  className='w-full p-1 border-none outline-none text-lg font-montserrat'
                  value={searchBarTwoInput}
                  onChange={(e) => setSearchBarTwoInput(e.target.value)}
                />
                <img
                  className='w-5 h-5 ml-2'
                  alt=''
                  src='/general--search.svg'
                />
              </div>
              {top100WorldLanguages
                .filter((language) =>
                  language
                    .toLowerCase()
                    .includes(searchBarTwoInput?.toLowerCase() || ''),
                )
                .map((language) => (
                  <div
                    key={language}
                    className='p-2 hover:bg-gray-200 cursor-pointer w-fit h-fit'
                    onClick={() => handleOutputLanguageClick(language)}
                  >
                    {language}
                  </div>
                ))}
            </div>
          )}
          <div className='relative shadow-[1px_1px_2.8px_rgba(140,_140,_140,_0.16)_inset] rounded-xl bg-updated-color-translate-box-bkg box-border w-full h-[448px] border-[0.5px] border-solid border-updated-color-grey1 pb-10'>
            <div
              className='flex flex-col gap-4 overflow-y-auto max-h-[95%]'
              ref={correctedMessagesContainerRef}
            >
              {messagesMerged.map((message, index) => {
                const localTime = format(
                  new Date(message.timestamp * 1000),
                  'hh:mm:ss a',
                );
                return (
                  <div key={index} className='flex items-start m-4'>
                    <span className='mr-4 text-gray-600 w-24'>{localTime}</span>
                    <p>
                      {outputLanguage.current === 'Original' ||
                      outputLanguage.current === 'Detect Language'
                        ? message.transcript
                        : message.corrected_transcripts[outputLanguage.current]}
                    </p>
                  </div>
                );
              })}
            </div>

            <div className='absolute bottom-2 left-0 right-0 mx-auto w-fit flex flex-col items-center gap-[20px]'>
              {correctedMessagesShouldShowScrollButton && (
                <div
                  className=' cursor-pointer'
                  onClick={correctedScrollToBottom}
                >
                  <Icon path={mdiArrowDown} size={1} />
                </div>
              )}
              <div className='flex items-center gap-4'>
                <img
                  className='w-[34px] relative h-[34px] cursor-pointer'
                  alt=''
                  src='/group-1707478179.svg'
                  onClick={() => {
                    var copiedTranscript = '';
                    for (var i = 0; i < messagesMerged.length; i++) {
                      const message = messagesMerged[i];
                      copiedTranscript +=
                        message.corrected_transcripts[outputLanguage.current] +
                        '\n\n';
                    }
                    playAudio(copiedTranscript);
                  }}
                />
                <img
                  className='w-[34px] relative h-[34px] cursor-pointer'
                  alt=''
                  src='/group-1707478175.svg'
                  onClick={() => {
                    var copiedTranscript = '';
                    for (var i = 0; i < messagesMerged.length; i++) {
                      const message = messagesMerged[i];
                      var localTime = format(
                        new Date(message.timestamp * 1000),
                        'hh:mm:ss a',
                      );
                      copiedTranscript +=
                        localTime +
                        ': ' +
                        message.corrected_transcripts[outputLanguage.current] +
                        '\n\n';
                    }
                    navigator.clipboard.writeText(copiedTranscript);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col gap-4'>
          <div className='flex items-center w-[300px] rounded-full'>
            <img src='/cam.svg' alt='' className='w-6 h-6 -mr-7' />
            <input
              placeholder='Paste meeting URL to get caption'
              className='text-base px-4 pl-8 py-2 w-full border-solid rounded-full border-[.5px] focus:outline-none bg-transparent'
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  addBotToMeeting(e.target.value);
                }
              }}
            />
          </div>
          <button
            className='w-[250px] bg-blue-600 hover:bg-blue-700 text-white rounded-full py-2 px-6 flex items-center gap-2 shadow-lg transition duration-300 ease-in-out transform hover:scale-105'
            onClick={() => {
              alert(
                'Coming Soon! Contact support@interpretapp.ai for early access.',
              );
            }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20' // Reduced width for better alignment
              height='20' // Reduced height
              viewBox='0 0 50 50'
              fill='white'
              stroke='white'
              strokeWidth='2'
            >
              <path d='M 16.380859 4 C 9.5545055 4 4 9.5545055 4 16.380859 L 4 33.619141 C 4 40.445495 9.5545055 46 16.380859 46 L 33.619141 46 C 40.445495 46 46 40.445495 46 33.619141 L 46 16.380859 C 46 9.5545055 40.445495 4 33.619141 4 L 16.380859 4 z M 16.380859 6 L 33.619141 6 C 39.364786 6 44 10.635214 44 16.380859 L 44 33.619141 C 44 39.364786 39.364786 44 33.619141 44 L 16.380859 44 C 10.635214 44 6 39.364786 6 33.619141 L 6 16.380859 C 6 10.635214 10.635214 6 16.380859 6 z M 37.203125 16.628906 C 36.897671 16.625906 36.588652 16.704536 36.306641 16.880859 L 30.673828 20.400391 C 29.994839 18.439614 28.178052 17 25.994141 17 L 13 17 A 1.0001 1.0001 0 0 0 12 18 L 12 27.994141 C 12 30.746526 14.253474 33 17.005859 33 L 30 33 A 1.0001 1.0001 0 0 0 31 32 L 31 28.802734 L 36.306641 32.119141 C 37.434686 32.824447 39 31.957395 39 30.626953 L 39 18.373047 C 39 17.375216 38.119486 16.63778 37.203125 16.628906 z M 37 18.804688 L 37 30.195312 L 31 26.445312 L 31 22.554688 L 37 18.804688 z M 14 19 L 25.994141 19 C 27.665755 19 29 20.334245 29 22.005859 L 29 27 L 29 31 L 17.005859 31 C 15.334245 31 14 29.665755 14 27.994141 L 14 19 z'></path>
            </svg>
            <span className='text-base'>Integrate with Zoom</span>
          </button>
          <RecentsSidebar></RecentsSidebar>
        </div>
      </div>
    </div>
  );
};

export default Transcribe;
