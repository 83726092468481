import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtTokenRef, serverURL, userInfoRef } from '../../httpContext';
import axios from 'axios';

const EmailVerification = () => {
  const navigate = useNavigate();

  const onGroupIconClick = useCallback(() => {
    navigate('/signup');
  }, [navigate]);

  useEffect(() => {
    const checkEmailVerification = () => {
      if (userInfoRef.current?.verified_email) {
        navigate('/transcribe');
      }
    };

    const interval = setInterval(checkEmailVerification, 1000);

    return () => clearInterval(interval);
  }, [navigate]);

  return (
    <div className='w-full relative bg-white h-[982px] overflow-hidden text-left text-21xl text-not-black-black font-montserrat'>
      <div className='absolute top-[207px] left-[calc(50%_-_191px)] flex flex-col items-center justify-start'>
        <div className='relative leading-[123%] font-semibold'>
          Create an Account
        </div>
      </div>
      <div className='absolute top-[calc(50%_-_33px)] left-[calc(50%_-_262px)] flex flex-col items-center justify-start gap-[20px] text-center text-xl text-updated-color-new-black'>
        <div className='relative font-medium'>
          Verification code has been sent to {userInfoRef.current?.email}
        </div>
        <div className='relative text-lg text-unselected-text'>
          <span>{`Didn’t receive email! `}</span>
          <span
            className='[text-decoration:underline] text-dodgerblue-200 cursor-pointer'
            onClick={async () => {
              // resend email
              try {
                const response = await axios.post(
                  `${serverURL}/request-email-verification`,
                  {},
                  {
                    headers: {
                      Authorization: `Bearer ${jwtTokenRef.current}`,
                      'Content-Type': 'application/json',
                    },
                  },
                );
                if (response.status === 200) {
                  console.log('Email verification sent');
                } else if (response.status === 403) {
                  console.log('Email verification failed: Forbidden');
                } else {
                  console.log('Email verification failed');
                }
              } catch (error) {
                console.error('Error during email verification:', error);
              }
            }}
          >
            Resend
          </span>
        </div>
      </div>
      <div className='absolute top-[0px] left-[50px] w-[1362px] h-24 flex flex-row items-center justify-start pt-[55px] px-0 pb-[30px] box-border gap-[796px] text-5xl text-updated-color-blue'>
        <div className='w-[183px] h-[41px] flex flex-row items-center justify-start'>
          <div className='w-44 relative h-[41px]'>
            <div className='absolute w-[73.86%] top-[14.63%] left-[26.14%] tracking-[-0.02em] font-medium inline-block'>
              interpret
            </div>
            <img
              className='absolute h-full w-[23.3%] top-[0%] right-[76.7%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full'
              alt=''
              src='/1024px-x-1024px-round-w-text1.svg'
            />
          </div>
        </div>
        <div className='flex flex-row items-start justify-start gap-[50px] text-center text-xl text-not-black-black'>
          <div className='flex flex-row items-center justify-center py-[5px] px-[15px]'>
            <div className='relative font-medium opacity-[0]'>About</div>
          </div>
          <div className='flex flex-row items-center justify-center py-[5px] px-[15px]'>
            <div className='relative font-medium opacity-[0]'>Pricing</div>
          </div>
          <div className='flex flex-row items-center justify-center py-[5px] px-[15px]'>
            <div className='relative font-medium opacity-[0]'>Login</div>
          </div>
        </div>
      </div>
      <img
        className='absolute h-[2.75%] w-[1.79%] top-[22.2%] right-[92.59%] bottom-[75.05%] left-[5.62%] max-w-full overflow-hidden max-h-full cursor-pointer'
        alt=''
        src='/group-1707478204.svg'
        onClick={onGroupIconClick}
      />
    </div>
  );
};

export default EmailVerification;
