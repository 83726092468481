import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { socket } from '../../socketContext';
import { top100WorldLanguages } from '../../languages';
import { jwtTokenRef, translateTexts, userInfoRef } from '../../httpContext';
import RoomLinkAndQRCode from '../../components/mobile/RoomLinkAndQRCode';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import { format } from 'date-fns';
import { serverURL } from '../../httpContext';
import axios from 'axios';

const formatDate = (date) => {
  const options = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
  return formattedDate;
};

const BroadcastRoom = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;

  const [showLinkQRCode, setShowLinkQRCode] = useState(false);
  const [messages, setMessages] = useState([]);
  const [messagesMerged, setMessagesMerged] = useState([]);
  const [shouldShowScrollButton, setShouldShowScrollButton] = useState(false);
  const [languageDropdownVisible, setLanguageDropdownVisible] = useState(false);
  const [searchBarInput, setSearchBarInput] = useState('');
  const [inputLanguageDisplay, setInputLanguageDisplay] = useState('Original');
  const [showBackTooltip, setShowBackTooltip] = useState(false);
  const [showShareTooltip, setShowShareTooltip] = useState(false);

  const linkAndQRCodeRef = useRef(null);
  const messagesRef = useRef(messages);
  const messagesContainerRef = useRef(null);
  const shouldScroll = useRef(false);
  const usingInputLanguagePopupRef = useRef(false);
  const inputLanguage = useRef(null);
  const inputLanguageRef = useRef(null);
  const inputLanguageDropdownRef = useRef(null);
  const [lottieRef, setLottieRef] = useState(null);
  const roomIdRef = useRef(null);

  const date = new Date();
  const formattedDate = formatDate(date);

  const updateMessagesMerged = (messagesUpdated) => {
    let messagesMergedUpdated = [];
    for (let i = 0; i < messagesUpdated.length; i++) {
      if (messagesUpdated[i]['transcript'] != '') {
        const currentTimestamp = messagesUpdated[i]['timestamp'];
        const lastMergedMessage =
          messagesMergedUpdated[messagesMergedUpdated.length - 1];

        if (
          i > 0 &&
          !messagesUpdated[i]['did_speaker_change'] &&
          !messagesUpdated[i]['is_ai_answer'] &&
          !messagesUpdated[i - 1]['is_ai_answer'] &&
          messagesMergedUpdated.length > 0 &&
          messagesUpdated[i - 1]['speaker'] === messagesUpdated[i]['speaker'] &&
          lastMergedMessage['messages_merged'] < 7 &&
          currentTimestamp - lastMergedMessage['timestamp'] <= 30 // Check if the time gap is less than or equal to 7 seconds
        ) {
          lastMergedMessage['transcript'] =
            lastMergedMessage['transcript'] + messagesUpdated[i]['transcript'];

          lastMergedMessage['messages_merged']++;

          for (let key in messagesUpdated[i]['corrected_transcripts']) {
            if (lastMergedMessage['corrected_transcripts'][key] == undefined) {
              lastMergedMessage['corrected_transcripts'][key] = '';
            }
            lastMergedMessage['corrected_transcripts'][key] =
              lastMergedMessage['corrected_transcripts'][key] +
              ' ' +
              messagesUpdated[i]['corrected_transcripts'][key];
          }
        } else {
          messagesUpdated[i]['messages_merged'] = 1;
          const timestamp = new Date(currentTimestamp * 1000);
          messagesUpdated[i]['timestamp_display'] =
            timestamp.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            });
          messagesMergedUpdated.push(messagesUpdated[i]);
        }
      }
    }
    console.log(messagesMergedUpdated);
    setMessagesMerged(messagesMergedUpdated);
  };

  useEffect(() => {
    // click outside the language picker popup to close it
    const handleClickOutside = (event) => {
      if (usingInputLanguagePopupRef.current) {
        if (
          inputLanguageRef.current &&
          !inputLanguageRef.current.contains(event.target)
        ) {
          if (
            inputLanguageDropdownRef.current &&
            !inputLanguageDropdownRef.current.contains(event.target)
          ) {
            usingInputLanguagePopupRef.current = false;
            setLanguageDropdownVisible(false);
          }
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const fetchRoomDetails = async () => {
    try {
      const response = await axios.get(
        `${serverURL}/room/${roomIdRef.current}`,
        {
          headers: { Authorization: `Bearer ${jwtTokenRef.current}` },
        },
      );
      const roomData = response.data;

      const messagesList = JSON.parse(roomData.messages);
      console.log('Messages:', messagesList);

      setMessages(messagesList);

      updateMessagesMerged(messagesList || []);
      // check if we should scroll
      const messagesContainer = messagesContainerRef.current;
      if (messagesContainer) {
        const { scrollTop, clientHeight, scrollHeight } = messagesContainer;
        const atBottom = Math.abs(scrollHeight - clientHeight - scrollTop) <= 1;
        shouldScroll.current = atBottom;
      }
    } catch (error) {
      console.error('Error fetching room details:', error);
    }
  };

  useEffect(() => {
    const roomId = pathname.split('/').pop();
    roomIdRef.current = roomId;
    const intervalId = setInterval(fetchRoomDetails, 1000);

    return () => clearInterval(intervalId); // Clear the interval when the component unmounts
  }, [pathname]);

  useEffect(() => {
    const correctedMessagesContainer = messagesContainerRef.current;
    if (correctedMessagesContainer) {
      let lastScrollTop = correctedMessagesContainer.scrollTop;

      const handleScroll = () => {
        const currentScrollTop = correctedMessagesContainer.scrollTop;
        if (currentScrollTop < lastScrollTop) {
          shouldScroll.current = false;
        }
        lastScrollTop = currentScrollTop;
      };

      if (correctedMessagesContainer) {
        correctedMessagesContainer.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (correctedMessagesContainer) {
          correctedMessagesContainer.removeEventListener(
            'scroll',
            handleScroll,
          );
        }
      };
    }
  }, []);

  const correctedScrollToBottom = () => {
    setShouldShowScrollButton(false);
    messagesContainerRef.current?.scrollTo({
      top: Math.ceil(messagesContainerRef.current.scrollHeight),
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (shouldScroll.current) {
      correctedScrollToBottom();
    }
  }, [messagesMerged]);

  const handleInputLanguageClick = (language) => {
    setLanguageDropdownVisible(false);
    const addLanguage = async (language) => {
      const roomId = roomIdRef.current; // Assuming roomIdRef is defined and holds the current room ID
      try {
        const response = await axios.post(
          `${serverURL}/room/${roomId}/add_language`,
          {
            language: language,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtTokenRef.current}`,
            },
          },
        );
        if (response.status === 200) {
          console.log('Language added successfully:', response.data);
        } else {
          console.error('Failed to add language:', response.status);
        }
      } catch (error) {
        console.error('Error adding language:', error);
      }
    };
    addLanguage(language);
    if (language != inputLanguage.current) {
      let textsToTranslate = [];
      let messagesAlreadyTranslated = [];
      for (let i = 0; i < messagesRef.current.length; i++) {
        let message = messagesRef.current[i];
        if (message['corrected_transcripts'][language] != undefined) {
          messagesAlreadyTranslated.push(i);
          continue;
        }
        textsToTranslate.push(message.transcript);
      }
      console.log('texts to translate: ' + textsToTranslate);
      translateTexts(textsToTranslate, language).then((translations) => {
        console.log('translations: ' + translations);
        let updatedMessages = [];
        for (let i = 0; i < messagesRef.current.length; i++) {
          let message = messagesRef.current[i];
          if (messagesAlreadyTranslated.includes(i)) {
            updatedMessages.push(message);
            continue;
          } else {
            if (message['corrected_transcripts'][language] == undefined) {
              message['corrected_transcripts'][language] =
                translations[i] + ' ';
            }
            updatedMessages.push(message);
          }
        }
        setMessages(updatedMessages);
        updateMessagesMerged(updatedMessages);
        localStorage.setItem('messages', JSON.stringify(messagesMerged));
      });
    }
    inputLanguage.current = language;
    setInputLanguageDisplay(language);
  };

  useEffect(() => {
    const messagesContainer = messagesContainerRef.current;
    let lastScrollTop = messagesContainer.scrollTop;

    const handleScroll = () => {
      const currentScrollTop = messagesContainer.scrollTop;
      if (currentScrollTop < lastScrollTop) {
        // User is scrolling up
        shouldScroll.current = false;
      }
      lastScrollTop = currentScrollTop;
    };

    if (messagesContainer) {
      messagesContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (messagesContainer) {
        messagesContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        linkAndQRCodeRef.current &&
        !linkAndQRCodeRef.current.contains(event.target)
      ) {
        setShowLinkQRCode(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    // click outside the language picker popup to close it
    const handleClickOutside = (event) => {
      if (usingInputLanguagePopupRef.current) {
        if (
          inputLanguageRef.current &&
          !inputLanguageRef.current.contains(event.target)
        ) {
          if (
            inputLanguageDropdownRef.current &&
            !inputLanguageDropdownRef.current.contains(event.target)
          ) {
            usingInputLanguagePopupRef.current = false;
            setLanguageDropdownVisible(false);
          }
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className='flex bg-whitesmoke w-full h-full'>
      {showLinkQRCode && (
        <div className='fixed inset-0 z-50 flex items-center justify-center bg-black/20'>
          <RoomLinkAndQRCode
            link={'https://interpretapp.ai/broadcast/' + roomIdRef.current}
            onOutsideClickFunc={() => setShowLinkQRCode(false)}
            divRef={linkAndQRCodeRef}
            isWeb={true}
          />
        </div>
      )}
      <div
        className='h-screen w-28 shadow-md flex flex-col gap-6 items-end pt-12 pr-8'
        style={{
          background:
            'linear-gradient(103deg, rgba(0, 126, 243, 0.10) 0.03%, rgba(121, 119, 209, 0.10) 99.97%), let(--white, #FFF)',
        }}
      >
        <img src='/logo.svg' alt='logo' className='w-8 h-8 mb-16' />
        <button
          onClick={() => {
            if (userInfoRef.current) navigate('/transcribe');
            else navigate('/signup');
          }}
          className='relative font-montserrat hover:opacity-80'
          onMouseOver={() => setShowBackTooltip(true)}
          onMouseOut={() => setShowBackTooltip(false)}
        >
          <img
            src='/broadcastback.svg'
            alt='broadcastback'
            className='w-8 h-8'
          />
          {showBackTooltip && (
            <div
              className={`absolute top-[5px] -right-14 text-white bg-updated-color-new-black rounded-md p-1 px-2 transition-all duration-300 ${showBackTooltip ? 'opacity-80' : 'opacity-0'}`}
            >
              Back
            </div>
          )}
        </button>
        <button
          className='relative font-montserrat hover:opacity-80 transition-all duration-300'
          onClick={() => setShowLinkQRCode(true)}
          onMouseOver={() => setShowShareTooltip(true)}
          onMouseOut={() => setShowShareTooltip(false)}
        >
          <img
            src='/broadcastshare.svg'
            alt='broadcastback'
            className='w-8 h-8'
          />
          <div
            className={`absolute top-[5px] -right-14 text-white bg-updated-color-new-black rounded-md p-1 px-2 transition-all duration-300 ${showShareTooltip ? 'opacity-80' : 'opacity-0'}`}
          >
            Share
          </div>
        </button>
      </div>
      <div className='flex flex-col gap-6 justify-center w-full h-screen px-14'>
        <div className='flex justify-between items-center relative'>
          <div className='font-montserrat w-full mr-6'>
            Room Created {formattedDate}
          </div>
          <div
            ref={inputLanguageRef}
            className='w-fit rounded-34xl h-fit flex py-1.5 px-5 border-[0.5px] border-solid border-updated-color-blue cursor-pointer'
            onClick={() => {
              setLanguageDropdownVisible(!languageDropdownVisible);
              usingInputLanguagePopupRef.current =
                !usingInputLanguagePopupRef.current;
            }}
          >
            <div className='flex flex-col py-1.5 px-2.5'>
              <div className='flex   gap-[10px] cursor-pointer'>
                <b className='relative'>{inputLanguageDisplay}</b>
                <img
                  className='w-5 relative h-5'
                  alt=''
                  src='/select-more-language.svg'
                />
              </div>
            </div>
          </div>
          {languageDropdownVisible && (
            <div
              ref={inputLanguageDropdownRef}
              className='grid grid-cols-4 gap-8 px-10 bg-white border absolute top-12 right-0 border-solid border-gray-300 rounded-md shadow-lg z-10 max-h-96 overflow-y-auto w-full p-4'
            >
              <div className='col-span-4 h-fit flex items-center p-2 border-solid border-x-0 border-t-0 border-b border-gray-300'>
                <input
                  type='text'
                  placeholder='Search...'
                  className='w-full p-1 border-none outline-none text-lg font-montserrat'
                  value={searchBarInput}
                  onChange={(e) => setSearchBarInput(e.target.value)}
                />
                <img
                  className='w-5 h-5 ml-2'
                  alt=''
                  src='/general--search.svg'
                />
              </div>
              {top100WorldLanguages
                .filter((language) =>
                  language
                    .toLowerCase()
                    .includes(searchBarInput?.toLowerCase() || ''),
                )
                .map((language) => (
                  <div
                    key={language}
                    className=' hover:bg-gray-200 cursor-pointer w-full h-fit'
                    onClick={() => handleInputLanguageClick(language)}
                  >
                    {language}
                  </div>
                ))}
            </div>
          )}
        </div>
        <div
          className='flex relative flex-col w-full text-xs text-updated-color-new-black font-montserrat h-2/3 bg-white rounded-lg border-solid border-px border-updated-color-grey1'
          onTransitionEnd={() => {
            if (shouldScroll.current) {
              // check if at the bottom
              const messagesContainer = messagesContainerRef.current;
              if (messagesContainer) {
                const { scrollTop, clientHeight, scrollHeight } =
                  messagesContainer;
                const atBottom =
                  Math.abs(scrollHeight - clientHeight - scrollTop) <= 1;
                if (atBottom) {
                  shouldScroll.current = false;
                }
              }
            }
          }}
        >
          <div
            className='flex flex-col text-left w-12/12 pb-10 max-h-[70%] overflow-y-auto'
            ref={messagesContainerRef}
          >
            {messagesMerged.map((message, index) => {
              const localTime = format(
                new Date(message.timestamp * 1000),
                'hh:mm:ss a',
              );
              return (
                <div key={index} className='flex m-4'>
                  <span className='mr-4 text-gray-600 w-24'>{localTime}</span>
                  {message.speaker && (
                    <span className='mr-4 font-bold'>{message.speaker}: </span>
                  )}
                  <div>
                    {inputLanguage.current === 'Original' ||
                    inputLanguage.current === 'Detect Language'
                      ? message.transcript
                      : message.corrected_transcripts[inputLanguage.current]}
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className={`w-fit absolute z-10 bottom-16 mx-auto left-0 right-0 cursor-pointer`}
          >
            {shouldShowScrollButton && (
              <img
                className='w-full h-full overflow-hidden'
                loading='lazy'
                alt=''
                src='/arrow--arrow-down-6-circle.svg'
                onClick={() => {
                  correctedScrollToBottom();
                }}
              />
            )}
          </div>
          <div className='absolute bg-white bottom-0 w-full pt-2 pb-2 rounded-b-lg'>
            <div className='flex justify-center mx-auto items-center w-11/12 gap-2'>
              <hr className='border-dashed w-full border h-px border-b-0 border-x-0' />
              <div className='flex items-center justify-center'>
                <DotLottieReact
                  src='https://lottie.host/a425c32d-9541-45f1-95e0-2f3a44e31cb7/GWtgIB7950.json'
                  background='transparent'
                  speed='1'
                  loop
                  dotLottieRefCallback={(ref) => setLottieRef(ref)}
                  className='w-48'
                />
              </div>
              <hr className='border-dashed w-full border h-px border-b-0 border-x-0' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BroadcastRoom;
