import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { jwtTokenRef, serverURL } from '../../httpContext';
import ProfileDropdown from '../../components/ProfileDropdown';
import RecentsSidebar from './RecentsSidebar';
import { fileTranslateLanguages } from '../../languages';
import { LoadingOutlined } from '@ant-design/icons';
import { extractMeetingId } from '../../utils';

const LanguageSelectDropdown = ({
  isLanguageSelectOpen,
  setIsLanguageSelectOpen,
  fileTranslateLanguages,
  setOutputLanguage,
}) => {
  const [searchBarInput, setSearchBarInput] = useState('');
  const [newOutputLanguage, setNewOutputLanguage] = useState('');

  return (
    isLanguageSelectOpen && (
      <div className='fixed inset-0 bg-black/20 flex items-center justify-center z-20 font-montserrat'>
        <div className='flex flex-col items-center gap-10 bg-white rounded-xl p-10 w-1/2'>
          <div className='self-start text-lg'>Select Language</div>
          <div className='grid grid-cols-4 bg-white border-solid border-gray-300 rounded-md shadow-lg max-h-64 overflow-y-auto min-w-full p-4'>
            <div className='col-span-4 flex items-center p-2 border-b border-solid border-gray-300'>
              <input
                type='text'
                placeholder='Search...'
                className='w-full p-1 border-none outline-none text-lg'
                value={searchBarInput}
                onChange={(e) => setSearchBarInput(e.target.value)}
              />
              <img className='w-5 h-5 ml-2' alt='' src='/general--search.svg' />
            </div>
            {fileTranslateLanguages
              .filter((language) =>
                language.toLowerCase().includes(searchBarInput.toLowerCase()),
              )
              .map((language) => (
                <div
                  key={language}
                  className={`p-2 hover:bg-black/20 rounded-full cursor-pointer h-fit w-fit ${newOutputLanguage === language ? 'bg-dodgerblue-200 text-white' : ''}`}
                  onClick={() => {
                    console.log('setting new output language to', language);
                    setNewOutputLanguage(language);
                  }}
                >
                  {language}
                </div>
              ))}
          </div>
          <div className='flex self-end gap-5 text-base'>
            <div
              className='rounded-3xs flex items-center justify-center py-1 px-4 border border-solid border-gray-300 cursor-pointer'
              onClick={() => setIsLanguageSelectOpen(false)}
            >
              <div className='relative leading-5 font-medium'>Cancel</div>
            </div>
            <div
              className='rounded-3xs bg-gradient-to-r from-blue-500 to-blue-300 flex items-center justify-center py-1 px-4 border border-solid border-gray-300 cursor-pointer'
              onClick={() => {
                console.log('setting output language to', newOutputLanguage);
                setOutputLanguage(newOutputLanguage);
                setIsLanguageSelectOpen(false);
              }}
            >
              <div className='relative leading-5 font-medium'>Done</div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

const FileTranslate = () => {
  const navigate = useNavigate();

  const [error, setError] = useState('');
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [isLanguageSelectOpen, setIsLanguageSelectOpen] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [searchBarInput, setSearchBarInput] = useState('');
  const [outputLanguage, setOutputLanguage] = useState('');
  const [newOutputLanguage, setNewOutputLanguage] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0); // New state for upload progress

  const fileInputRef = useRef(null);

  const uploadFile = async (file) => {
    let endpoint = `/translate_file_async?output_language=${outputLanguage}&regenerate_pdf=false`;
    const formData = new FormData();
    formData.append('file', file, file.name);
    try {
      setUploadedFileName('');
      setIsTranscribing(true);
      const response = await axios.post(serverURL + endpoint, formData, {
        headers: {
          Authorization: 'Bearer ' + jwtTokenRef.current,
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (event) => {
          const { loaded, total } = event;
          const percentCompleted = Math.round((loaded / total) * 100);
          setUploadProgress(percentCompleted); // Update the progress
        },
      });

      if (response.status !== 200) {
        console.error('Error occurred during file upload.');
        setError('Error occurred during file upload.');
        setIsTranscribing(false);
        return;
      } else {
        const data = response.data;
        let transcriptId = data.transcript_id;
        setError('');
        navigate('/file_transcript?id=' + transcriptId);
      }
    } catch (error) {
      setIsTranscribing(false);
      setUploadedFileName('');
      console.error('Error occurred during file upload.', error);
      setError('Error occurred during file upload.');
    }
  };

  const changeUploadedFileEventLister = () => {
    if (fileInputRef.current.files.length > 0) {
      const fileName = fileInputRef.current.files[0].name;
      const shortenedFileName =
        fileName.length > 20
          ? fileName.substring(0, 20) + '...' + fileName.split('.').pop()
          : fileName;
      setUploadedFileName(shortenedFileName);
    }
  };

  const addEventListenerToFile = () => {
    const fileInput = fileInputRef.current;
    if (fileInput) {
      fileInput.addEventListener('change', changeUploadedFileEventLister);
    }

    return () => {
      if (fileInput) {
        fileInput.removeEventListener('change', changeUploadedFileEventLister);
      }
    };
  };

  const addBotToMeeting = async (meetingUrl) => {
    if (jwtTokenRef.current == undefined) {
      return;
    }

    const response = await axios.post(
      `${serverURL}/add_bot_to_zoom`,
      { meeting_url: meetingUrl },
      {
        headers: {
          Authorization: `Bearer ${jwtTokenRef.current}`,
        },
      },
    );
    if (response.status === 200) {
      console.log('Bot added to meeting');
    } else {
      console.error('Failed to add bot to meeting');
    }
    meetingUrl = extractMeetingId(meetingUrl);
    if (meetingUrl) {
      navigate(`/broadcast/${meetingUrl}`);
    }
  };

  useEffect(() => {
    addEventListenerToFile();
  }, []);

  return (
    <div className='relative'>
      <LanguageSelectDropdown
        isLanguageSelectOpen={isLanguageSelectOpen}
        setIsLanguageSelectOpen={setIsLanguageSelectOpen}
        fileTranslateLanguages={fileTranslateLanguages}
        setOutputLanguage={setOutputLanguage}
      />
      <div className='bg-white text-left text-xl text-updated-color-new-black font-montserrat flex flex-col'>
        <ProfileDropdown />
        {isTranscribing && (
          <div className='flex flex-col items-center justify-center gap-[20px] text-center text-[28px] py-36'>
            <LoadingOutlined
              className='text-[#007EF3] mb-6'
              style={{ fontSize: '60px' }}
            />
            <b className='relative leading-[22px]'>Translating...</b>
            <div className='relative text-lg leading-[22px] font-semibold'>
              Translating your file
            </div>
            <div className='relative text-lg leading-[22px] font-semibold'>
              Upload Progress: {uploadProgress}%
            </div>
          </div>
        )}
        {!isTranscribing && (
          <div className='flex gap-20 min-h-screen px-36 py-10'>
            <div className='gap-4 [filter:drop-shadow(0px_3px_5.5px_rgba(140,_140,_140,_0.25))] rounded-xl flex flex-col items-center justify-center px-40 text-[24px] border-[1px] border-solid border-updated-color-grey1 h-fit py-20'>
              <div className='flex flex-col items-center justify-start gap-[25px] mb-4'>
                <img className='w-fit h-fit' alt='' src='/file-translate.svg' />
                <div className='flex flex-col items-center justify-start gap-[10px]'>
                  <div className='relative leading-[22px] font-semibold'>
                    Translate Document
                  </div>
                  <div className='w-[420px] relative text-base leading-[22px] text-center inline-block'>
                    All document types are supported.
                    <br />
                    <br /> .PDF .DOCX .XLSX .PPTX .EPUB .PO .SRT .TXT .HTML .ZIP
                    .XML .XLF .GO .JPG .JPEG .PNG .MD .ODT .ODS .ODP .CSV .TSV
                    .YAML .PHP .AI
                  </div>
                </div>
              </div>
              <div className='flex text-center text-sm cursor-pointer relative'>
                <input
                  type='file'
                  accept='.pdf, .docx, .xlsx, .pptx, .epub, .po, .srt, .txt, .html, .zip, .xml, .xlf, .go, .jpg, .jpeg, .png, .md, .odt, .ods, .odp, .csv, .tsv, .yaml, .php, .ai'
                  ref={fileInputRef}
                  className='absolute opacity-0 w-full h-full z-10 cursor-pointer'
                />
                <div className='flex flex-row items-center justify-center'>
                  <div className='rounded-tl-23xl rounded-tr-none rounded-br-none rounded-bl-23xl bg-white flex flex-row items-center justify-center py-0.5 px-[15px] border-[0.5px] border-solid border-updated-color-grey1'>
                    <div className='relative leading-[22px]'>
                      {uploadedFileName ? uploadedFileName : 'Select a file'}
                    </div>
                  </div>
                  <div className='rounded-tl-none rounded-tr-22xl rounded-br-22xl rounded-bl-none bg-white flex flex-row items-center justify-center py-1 px-[5px] border-[0.5px] border-solid border-updated-color-grey1'>
                    <img
                      className='w-[18px] relative h-[18px] overflow-hidden shrink-0'
                      alt=''
                      src='/arrow--arrow-down-2.svg'
                    />
                  </div>
                </div>
              </div>
              <div
                className='flex text-center text-sm cursor-pointer relative'
                onClick={() => {
                  setIsLanguageSelectOpen(true);
                }}
              >
                <div className='flex flex-row items-center justify-center'>
                  <div className='rounded-tl-23xl rounded-tr-none rounded-br-none rounded-bl-23xl bg-white flex flex-row items-center justify-center py-0.5 px-[15px] border-[0.5px] border-solid border-updated-color-grey1'>
                    <div className='relative leading-[22px]'>
                      {outputLanguage || 'Select a language'}
                    </div>
                  </div>
                  <div className='rounded-tl-none rounded-tr-22xl rounded-br-22xl rounded-bl-none bg-white flex flex-row items-center justify-center py-1 px-[5px] border-[0.5px] border-solid border-updated-color-grey1'>
                    <img
                      className='w-[18px] relative h-[18px] overflow-hidden shrink-0'
                      alt=''
                      src='/arrow--arrow-down-2.svg'
                    />
                  </div>
                </div>
              </div>
              <div
                className='rounded-3xs [background:linear-gradient(rgba(0,_126,_243,_0.3),_rgba(0,_126,_243,_0.3)),_#fff] flex items-center justify-center py-[5px] px-[15px] border-[0.5px] border-solid border-updated-color-grey1 cursor-pointer'
                onClick={() => {
                  uploadFile(fileInputRef.current.files[0]);
                }}
              >
                <div className='relative font-medium text-base'>Translate</div>
              </div>
            </div>
            <div className='flex flex-col gap-4'>
              <div className='flex items-center w-[300px] rounded-full'>
                <img src='/cam.svg' alt='' className='w-6 h-6 -mr-7' />
                <input
                  placeholder='Paste meeting URL to get caption'
                  className='text-base px-4 pl-8 py-2 w-full border-solid rounded-full border-[.5px] focus:outline-none bg-transparent'
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      addBotToMeeting(e.target.value);
                    }
                  }}
                />
              </div>
              <button
                className='w-[250px] bg-blue-600 hover:bg-blue-700 text-white rounded-full py-2 px-6 flex items-center gap-2 shadow-lg transition duration-300 ease-in-out transform hover:scale-105'
                onClick={() => {
                  alert(
                    'Coming Soon! Contact support@interpretapp.ai for early access.',
                  );
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20' // Reduced width for better alignment
                  height='20' // Reduced height
                  viewBox='0 0 50 50'
                  fill='white'
                  stroke='white'
                  strokeWidth='2'
                >
                  <path d='M 16.380859 4 C 9.5545055 4 4 9.5545055 4 16.380859 L 4 33.619141 C 4 40.445495 9.5545055 46 16.380859 46 L 33.619141 46 C 40.445495 46 46 40.445495 46 33.619141 L 46 16.380859 C 46 9.5545055 40.445495 4 33.619141 4 L 16.380859 4 z M 16.380859 6 L 33.619141 6 C 39.364786 6 44 10.635214 44 16.380859 L 44 33.619141 C 44 39.364786 39.364786 44 33.619141 44 L 16.380859 44 C 10.635214 44 6 39.364786 6 33.619141 L 6 16.380859 C 6 10.635214 10.635214 6 16.380859 6 z M 37.203125 16.628906 C 36.897671 16.625906 36.588652 16.704536 36.306641 16.880859 L 30.673828 20.400391 C 29.994839 18.439614 28.178052 17 25.994141 17 L 13 17 A 1.0001 1.0001 0 0 0 12 18 L 12 27.994141 C 12 30.746526 14.253474 33 17.005859 33 L 30 33 A 1.0001 1.0001 0 0 0 31 32 L 31 28.802734 L 36.306641 32.119141 C 37.434686 32.824447 39 31.957395 39 30.626953 L 39 18.373047 C 39 17.375216 38.119486 16.63778 37.203125 16.628906 z M 37 18.804688 L 37 30.195312 L 31 26.445312 L 31 22.554688 L 37 18.804688 z M 14 19 L 25.994141 19 C 27.665755 19 29 20.334245 29 22.005859 L 29 27 L 29 31 L 17.005859 31 C 15.334245 31 14 29.665755 14 27.994141 L 14 19 z'></path>
                </svg>
                <span className='text-base'>Integrate with Zoom</span>
              </button>
              <RecentsSidebar></RecentsSidebar>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FileTranslate;
