import { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SelectRoomToJoin = ({ onOutsideClickFunc }) => {
  const [roomId, setRoomId] = useState('');
  const roomIdInputRef = useRef(null);
  const navigate = useNavigate();

  // Detect clicks outside the ref'ed div
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        roomIdInputRef.current &&
        !roomIdInputRef.current.contains(event.target)
      ) {
        onOutsideClickFunc();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onOutsideClickFunc]);

  const handleJoinClick = () => {
    if (roomId.trim()) {
      navigate(`/broadcast/${roomId}`);
    } else {
      alert('Please enter a valid Room ID');
    }
  };

  return (
    <div className='w-full h-full flex justify-center items-center relative overflow-hidden cursor-pointer max-w-full max-h-full text-left text-lg text-updated-color-new-black font-montserrat'>
      <div
        ref={roomIdInputRef}
        className='flex flex-col items-center justify-center rounded-xl bg-gray-100 p-8 relative'
      >
        <div className='font-semibold mb-4 text-center'>
          Enter Room ID to Join
        </div>
        <input
          type='text'
          className='rounded-md w-full p-2 border border-gray-300'
          value={roomId}
          onChange={(e) => setRoomId(e.target.value)}
          placeholder='Room ID'
        />
        <button
          className={`mt-4 cursor-pointer [border:none] min-w-[250px] py-2.5 px-5 rounded-3xs flex flex-row items-center justify-center [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.6),_rgba(121,_119,_209,_0.62))]`}
          onClick={handleJoinClick}
        >
          Join Room
        </button>
        <div
          className='absolute top-4 right-4 cursor-pointer'
          onClick={onOutsideClickFunc}
        >
          <img
            className='w-4 h-4'
            alt='Close'
            src='/interface--close-circle-1.svg'
          />
        </div>
      </div>
    </div>
  );
};

export default SelectRoomToJoin;
