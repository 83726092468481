export const Contact = () => {
  return (
    <div className='w-full h-screen font-montserrat flex flex-col px-20 pt-10'>
      <div className='text-4xl font-semibold text-not-black'>Contact Us</div>
      <div className='text-xl text-not-black font-medium mt-4'>
        For any questions, concerns, or feedback, please contact us at:
        <div className='text-updated-color-grey2 font-medium text-sm leading-6 mt-4'>
          <div>
            Email:{' '}
            <a
              href='mailto:support@interpretapp.ai'
              className='text-dodgerblue font-medium text-sm leading-6'
            >
              support@interpretapp.ai
            </a>
          </div>
          <div>
            Phone:{' '}
            <a
              href='tel:+1-714-402-4029'
              className='text-dodgerblue font-medium text-sm leading-6'
            >
              (714) 402-4029
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
