export const Switch = ({ isOn, handleToggle, onColor, text }) => {
  return (
    <label className='flex items-center cursor-pointer'>
      <div className='relative'>
        <input
          type='checkbox'
          className='sr-only'
          checked={isOn}
          onChange={handleToggle}
        />
        <div
          className={`block w-10 h-6 rounded-full ${
            isOn ? onColor : 'bg-gray-600'
          } transition-colors duration-300 ease-in-out`}
        ></div>
        <div
          className={`absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-transform duration-300 ease-in-out ${
            isOn ? 'transform translate-x-full' : ''
          }`}
        ></div>
      </div>
      <div className='ml-1 text-gray-700 font-medium'>{text}</div>
    </label>
  );
};
